import React, { useState, useEffect } from "react";
import { Input, Row, Switch, Col, Form, Modal, Tooltip } from "antd";
import Pagination from "@mui/material/Pagination";
import { useTable, useRowSelect, useSortBy } from "react-table";
import NoData from "../../Helper/NoDataFound.jsx";
import ApiService from "../../services/APIServices.js";
import Stack from "@mui/material/Stack";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import Icons from "../../Helper/Icons.jsx";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
// import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import CryptoJS from "crypto-js";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { casesCheckList, envChecks } from "../../utils/constants.js";

export const VerificationCoordinatorList = () => {
  const [page, setPage] = useState(1);
  // const [inputValue, setInputValue] = useState();
  const [search, setSearch] = useState("");
  const [BCAInfo, setBCAInfo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [managerName, setManagerName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showAddVendor, setShowAddVendor] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [totalPages, setTotalPages] = useState();
  // const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  // const [companyArray, setCompanyArray] = useState([]);
  // const [clientArray, setClientArray] = useState([]);
  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedCoordinator, setSelectedCoordinator] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedChecks, setSelectedChecks] = useState([]);
  const [checksArray, setChecksArray] = useState([]);

  const checks = casesCheckList.filter((check) => envChecks[check.name]);

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/;
    return passwordRegex.test(password);
  };

  const secretKey = process.env.REACT_APP_SECRET_KEY;

  const encryptField = (field) => {
    if (!field) return field; // If the field is empty or null, return as is
    return CryptoJS.AES.encrypt(field, secretKey).toString();
  };

  const handleRemoveCheck = (index) => {
    const updatedChecks = selectedCoordinator.assignedChecks.filter(
      (_, i) => i !== index
    );

    setSelectedCoordinator({
      ...selectedCoordinator,
      assignedChecks: updatedChecks,
    });
  };

  const handleRemoveSelectedCheck = (index) => {
    const updatedSelectedChecks = selectedChecks.filter((_, i) => i !== index);
    setSelectedChecks(updatedSelectedChecks);

    const updatedChecksArray = updatedSelectedChecks.map((check) => check.name);
    setChecksArray(updatedChecksArray);
  };

  // let queryParam = `/?limit=100&BCAId=${LocalData._id}`;

  // const getAllCompany = async () => {
  //   try {
  //     const res = await ApiService.get(`company/BCAId${queryParam}`);
  //     console.log("RES>>", res.data.docs);

  //     setCompanyList(res?.data?.docs);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   getAllCompany();
  // }, []);

  useEffect(() => {
    console.log("SelectedCompany>>", selectedCompany);
  }, [selectedCompany]);

  const checksTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  // const [hasVendorAccess, setHasVendorAccess] = useState(false);

  const [form] = Form.useForm();

  // const handleInputChange = (e) => {
  //   const newSearch = e.target.value;
  //   setInputValue(newSearch);
  // };
  let queryParams = `?limit=4&page=${page}`;
  if (search) {
    queryParams += `&name=${search}`;
  }
  const getVendorList = () => {
    let BCA = JSON.parse(localStorage.getItem("user"));

    let id = BCA.role === "BCA" ? BCA._id : BCA.BCAId;

    ApiService.get(`BCAStaff/fieldCoordinator/${id}${queryParams}`, {
      text: "",
    })
      .then((res) => {
        setVendorList(res?.data?.docs);
        setTotalPages(res?.data?.totalPages);
        setSpinner(false);
        // setHasVendorAccess(res?.data?.hasAccess);
      })
      .catch((error) => {
        setSpinner(false);
        console.error("Error fetching data from API:", error);
      });
  };

  const addVendor = () => {
    if (!validatePassword(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }
    setIsLoading(true);
    const body = {
      BCAId: BCAInfo.role === "BCA" ? BCAInfo._id : BCAInfo.BCAId,
      CCId: BCAInfo._id,
      assignedChecks: checksArray,
      vendorName: vendorName,
      managerName: managerName,
      userName: userName,
      password: encryptField(password),
      email: email,
      phoneNumber: phoneNumber,
      address: address,
      role: "FieldCoordinator",
    };
    ApiService.post("BCAStaff", body)
      .then(() => {
        setIsLoading(false);
        toast.success("Coordinator Added Successfully");
        setShowAddVendor(false);
        setSelectedCompany([]);
        form.resetFields();
        getVendorList();
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong");
        console.log(err);
      });
  };
  useEffect(() => {
    getVendorList();
  }, [page, search]);

  const onchange = (id, newValue) => {
    ApiService.post(`BCAStaff/${id}`, { hasStaffAccess: newValue })
      .then(() => {
        setVendorList((prevData) =>
          prevData.map((item) =>
            item._id === id ? { ...item, hasStaffAccess: newValue } : item
          )
        );
        // setHasVendorAccess(newValue);
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };

  const handleSubmit = () => {
    // Merge old checks with the newly selected checks
    const updatedChecks = [
      ...selectedCoordinator?.assignedChecks, // existing assigned checks
      ...checksArray, // newly selected checks
    ].filter(
      (check, index, self) => index === self.findIndex((c) => c === check) // Ensures no duplicates
    );

    // Send the merged array to the backend
    ApiService.post(`BCAStaff/${selectedCoordinator?._id}`, {
      assignedChecks: updatedChecks,
    })
      .then(() => {
        toast.success("Check updated successfully");
        setVisibleModal(false);
        setSelectedChecks([]);
        getVendorList(); // Refresh the data after successful update
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error in check update");
      });
  };

  const confirm1 = (id) => {
    confirmDialog({
      message: (
        <div className="flex items-center pt-[17px]">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          <span className="pt-[2px]">Are you sure you want to DELETE?</span>
        </div>
      ),
      header: "Confirmation",
      accept: () => accept(id),
      reject: () => setDialogVisible(false), // Close dialog on reject
      onHide: () => setDialogVisible(false), // Close dialog on close
    });
  };

  const accept = (id) => {
    deleteMember(id);
  };

  const deleteMember = (id) => {
    const url = `BCA/remove/${id}`;

    ApiService.post(url)
      .then(() => {
        setDialogVisible(false);
        toast.success("Member Deleted Successfully");
        getVendorList();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Delete Member");
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Coordinator Name",
        accessor: "vendorName",
      },
      {
        Header: "User Name",
        accessor: "userName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
      },
      {
        Header: "Checks",
        accessor: "",
        Cell: ({ row }) => {
          console.log("ROWWWWW>>>>>", row);
          return (
            (
              <>
                <div
                  role="presentation"
                  onClick={() => {
                    setVisibleModal(true);
                    setSelectedCoordinator(row?.original);
                  }}
                  className="flex cursor-pointer align-items-center gap-2"
                >
                  <Icons name="sidebar-arrow" />
                </div>
              </>
            ) || <span>NA</span>
          );
        },
      },
      {
        Header: "Access",
        accessor: "hasStaffAccess",
        Cell: ({ row }) => (
          <Switch
            checked={row?.original?.hasStaffAccess}
            onChange={() =>
              onchange(row?.original?._id, !row?.original?.hasStaffAccess)
            }
          />
        ),
      },
      {
        Header: "Delete",
        Cell: ({ row }) => {
          const id = row?.original?._id;
          return (
            <div
              className="cursor-pointer"
              role="presentation"
              onClick={() => confirm1(id)}
            >
              <DeleteIcon style={{ color: "red" }} />
              {/* <ConfirmDialog /> */}
            </div>
          );
        },
      },
    ],
    []
  );

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data: vendorList },
    useSortBy,
    useRowSelect
  );

  const handleChange = (event, value) => {
    setPage(value);
  };
  // const handlePhoneNumberChange = (e) => {
  //   const inputNumber = e.target.value;
  //   if (/^[0-9\s-]*$/.test(inputNumber)) {
  //     setPhoneNumber(inputNumber);
  //   }
  // };
  const getUserInfo = () => {
    let BCA = JSON.parse(localStorage.getItem("user"));
    setBCAInfo(BCA);
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  return (
    <>
      <div className="container mx-auto bg-white mt-6">
        <div className="flex flex-row justify-end mt-[30px] xl:mt-0 w-full">
          {BCAInfo?.role !== "Vendor" &&
          process.env.REACT_APP_VENDOR_MANAGEMENT_FEATURE_ENABLE === "true" ? (
            <>
              <Button
                label="Add Coordinator"
                icon="pi pi-plus"
                onClick={() => setShowAddVendor(true)}
                className="text-white !text-[14px] h-[41px] !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
              />
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="flex flex-col mt-[20px]">
          <div className=" shadow p-[30px]  overflow-x-auto ">
            <div className="inline-block min-w-full  align-middle ">
              <div className="justify-between flex flex-row">
                {" "}
                <div className=" text-black text-[22px] font-semibold ">
                  Verification-Coordinator&apos;s List
                </div>
                <div className="flex flex-row gap-[5px] ">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      className="h-[39px] max-sm:w-[10rem]"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      placeholder="Search by Name"
                    />
                  </span>
                </div>
              </div>

              <div className="w-full mt-[20px]  flex md:flex md:overflow-x-auto lg:overflow-x-visible md:rounded-lg">
                <table className="mt-2 min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-white">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        className="px-6 py-4"
                        key={Math.random().toString(36).substr(2, 9)}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            className="py-3.5 px-4 text-gray-400 text-sm font-medium rtl:text-right text-nowrap "
                            key={Math.random().toString(36).substr(2, 9)}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ""
                                  : ""
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900"
                    {...getTableBodyProps()}
                  >
                    {spinner ? (
                      <tr>
                        <td colSpan={columns?.length}>
                          <div className="text-center mt-1">
                            <ProgressSpinner
                              style={{ width: "50px", height: "50px" }}
                              strokeWidth="8"
                              animationDuration=".7s"
                            />
                          </div>
                        </td>
                      </tr>
                    ) : vendorList?.length === 0 ? (
                      <tr>
                        <td colSpan={columns?.length}>
                          <NoData info="Ooops, we could not find any case" />
                        </td>
                      </tr>
                    ) : (
                      rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap"
                            key={Math.random().toString(36).substr(2, 9)}
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell) => {
                              const cellValue = cell.value || "";
                              const columnHeader = cell.column.render("Header");
                              return (
                                <td
                                  className="px-4 py-4 text-zinc-800 text-sm font-medium whitespace-nowrap"
                                  key={Math.random().toString(36).substr(2, 9)}
                                  {...cell.getCellProps()}
                                >
                                  {columnHeader === "User Name" ||
                                  columnHeader === "Email" ? (
                                    <Tooltip placement="top" title={cellValue}>
                                      <span>
                                        {cellValue?.substring(0, 5)}...
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    cell.render("Cell")
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-center mt-4 mb-4">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages}
                    onChange={handleChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    page={page}
                  />
                </Stack>
              </div>
            </div>
          </div>

          <Modal
            width="40%"
            title="Add Your Verification-Coordinator"
            open={showAddVendor}
            footer={null}
            onCancel={() => setShowAddVendor(false)}
            className="custom-modal-content modalRespo"
          >
            <Form
              layout="vertical"
              onFinish={addVendor}
              className="add-new-wallet"
              form={form}
            >
              <p className="mt-2">Let’s add your Verification-Coordinator</p>
              <Row gutter={18} className="registerinfo-row">
                <Col span={24}>
                  <div>
                    <lable>Select Checks</lable>
                    <MultiSelect
                      value={selectedChecks}
                      options={checks}
                      onChange={(e) => {
                        setSelectedChecks(e.value);

                        const selectedChecksNames = e.value.map(
                          (check) => check.name
                        );
                        setChecksArray(selectedChecksNames);
                      }}
                      optionLabel="name"
                      // placeholder="Select Type of Checks"
                      itemTemplate={checksTemplate}
                      className="w-full md:w-20rem"
                      display="chip"
                      filter
                    />
                  </div>
                </Col>
              </Row>
              <Row gutter={18} className="registerinfo-row">
                <Col span={12}>
                  <lable>Coordinator Name</lable>
                  <Form.Item
                    name="vendorName" // Unique name for the field
                    rules={[
                      {
                        required: true,
                        message: "Coordinator name is required",
                      },
                    ]}
                  >
                    <Input
                      value={vendorName}
                      placeholder="Enter Coordinator name"
                      size="large"
                      onChange={(e) => {
                        const regex = /^[a-zA-Z\s-]*$/;
                        const newValue = e.target.value;
                        if (regex.test(newValue) || newValue === "") {
                          setVendorName(newValue);
                        }
                      }}
                      onKeyPress={(e) => {
                        const regex = /^[a-zA-Z\s-]$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <lable>Manager Name</lable>
                  <Form.Item
                    name="managerName" // Unique name for the field
                    rules={[
                      {
                        required: true,
                        message: "Manager name is required",
                      },
                    ]}
                  >
                    <Input
                      value={managerName}
                      placeholder="Enter Manager Name"
                      size="large"
                      onChange={(e) => {
                        const regex = /^[a-zA-Z\s-]*$/;
                        const newValue = e.target.value;
                        if (regex.test(newValue) || newValue === "") {
                          setManagerName(newValue);
                        }
                      }}
                      onKeyPress={(e) => {
                        const regex = /^[a-zA-Z\s-]$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={18} className="registerinfo-row">
                <Col span={12}>
                  <lable>Email</lable>
                  <Form.Item
                    name="email" // Unique name for the field
                    rules={[
                      {
                        required: true,
                        message: "Email is required",
                      },
                    ]}
                  >
                    <Input
                      value={email}
                      size="large"
                      placeholder="Enter Email Address"
                      onChange={(e) => {
                        const regex = /^[a-zA-Z0-9\s@._-]*$/;
                        const newValue = e.target.value;
                        if (regex.test(newValue) || newValue === "") {
                          setEmail(newValue);
                        }
                      }}
                      onKeyPress={(e) => {
                        const regex = /^[a-zA-Z0-9\s@._-]*$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <lable>Phone Number</lable>
                  <Form.Item
                    name="phoneNumber" // Unique name for the field
                    rules={[
                      {
                        required: true,
                        message: "Phone number is required",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Please enter a valid phone number!",
                      },
                    ]}
                  >
                    <Input
                      value={phoneNumber}
                      size="large"
                      placeholder="Enter Phone No"
                      maxLength={10}
                      // onChange={handlePhoneNumberChange}
                      onChange={(e) => {
                        const regex = /^[0-9\s-]*$/;
                        const newValue = e.target.value;
                        if (regex.test(newValue) || newValue === "") {
                          setPhoneNumber(newValue);
                        }
                      }}
                      onKeyPress={(e) => {
                        const regex = /^[0-9\s-]*$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={18} className="registerinfo-row">
                <Col span={24}>
                  <label htmlFor="state">Full address</label>
                  <Form.Item
                    name="address" // Unique name for the field
                    rules={[
                      {
                        required: true,
                        message: "Adress is required",
                      },
                    ]}
                  >
                    <Input
                      value={address}
                      placeholder="Enter State , City , Pincode"
                      size="large"
                      // onChange={(e) => {
                      //   setState(e.target.value);
                      // }}
                      onChange={(e) => {
                        const regex = /^[a-zA-Z\s-]*$/;
                        const newValue = e.target.value;
                        if (regex.test(newValue) || newValue === "") {
                          setAddress(newValue);
                        }
                      }}
                      onKeyPress={(e) => {
                        const regex = /^[a-zA-Z\s-]*$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={18} className="registerinfo-row">
                <Col span={12}>
                  <label htmlFor="userName">User Name (Required)</label>
                  <Form.Item
                    name="userName" // Unique name for the field
                    rules={[
                      {
                        required: false,
                        message: "name is required",
                      },
                    ]}
                  >
                    <Input
                      value={userName}
                      placeholder="Enter User Name"
                      size="large"
                      onChange={(e) => {
                        const regex = /^[a-zA-Z0-9\s-]*$/;
                        const newValue = e.target.value;
                        if (regex.test(newValue) || newValue === "") {
                          setUserName(newValue);
                        }
                        // setUserName(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        const regex = /^[a-zA-Z0-9\s-]*$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <label htmlFor="password">Password (Required)</label>
                  <Form.Item
                    name="password" // Unique name for the field
                  >
                    <Password
                      value={password}
                      placeholder="Enter Password"
                      size="large"
                      toggleMask
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      className="w-full h-[40px]"
                    />
                    {passwordError && (
                      <div style={{ color: "red" }}>{passwordError}</div>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <div className="mt-[12px] text-center">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-[141px] text-white text-xs font-medium h-[37px] bg-gradient-to-r from-sky-800 to-teal-400 rounded-[5px] shadow"
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Wait...
                    </>
                  ) : (
                    "Add Coordinator"
                  )}
                </button>
              </div>
            </Form>
          </Modal>
          <Modal
            width="30%"
            title="Select Checks"
            open={visibleModal}
            footer={null}
            onCancel={() => setVisibleModal(false)}
            className="custom-modal-content modalRespo"
          >
            <div>
              <MultiSelect
                value={selectedChecks}
                options={checks}
                onChange={(e) => {
                  setSelectedChecks(e.value);

                  const selectedChecksNames = e.value.map(
                    (check) => check.name
                  );
                  setChecksArray(selectedChecksNames);
                }}
                optionLabel="name"
                placeholder="Select Type "
                itemTemplate={checksTemplate}
                className="w-full md:w-20rem"
                display="chip"
                filter
              />
            </div>

            <div className="mt-[20px]">
              <div className="text-black text-[22px] font-semibold">
                Selected Checks:
              </div>
              <ul className="!pl-0">
                {selectedCoordinator?.assignedChecks?.map((check, index) => (
                  <li
                    key={check + index}
                    className="flex items-center justify-between bg-gradient-to-r from-sky-800 to-teal-400 font-semibold text-transparent bg-clip-text text-sm font-medium whitespace-nowrap my-1"
                  >
                    <span>{check}</span>
                    <button
                      className="text-red-500 hover:text-red-700 ml-2"
                      onClick={() => handleRemoveCheck(index)}
                    >
                      ✖
                    </button>
                  </li>
                ))}
              </ul>
              <ul className="!pl-0">
                {selectedChecks.map((check, index) => (
                  <li
                    key={check.name + index}
                    className="flex items-center justify-between bg-gradient-to-r from-sky-800 to-teal-400 font-semibold text-transparent bg-clip-text text-sm font-medium whitespace-nowrap my-1"
                  >
                    <span>{check.name}</span>
                    <button
                      className="text-red-500 hover:text-red-700 ml-2"
                      onClick={() => handleRemoveSelectedCheck(index)}
                    >
                      ✖
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-3">
              <Button
                className="w-[100px] max-[700px]:mt-2 text-white text-xs font-medium h-[41px] !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow"
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>
            </div>
          </Modal>
        </div>
      </div>
      <ConfirmDialog visible={dialogVisible} />
    </>
  );
};
