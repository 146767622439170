import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Link } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const EmploymentCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

const getStatusColor = (status) => {
  switch (status) {
    case "Major Discrepancy":
      return "red";
    case "Minor Discrepancy":
      return "orange";
    case "Verified Clear":
      return "green";
    case "Insufficiency":
      return "#859bed";
    case "Unable to Verify":
      return "orange";
    case "Stop Check":
      return "gray";
    case "Rejected":
      return "red";
    case "Approved":
      return "green";
    case "Completed":
      return "green";
    case "InProgress":
      return "#859bed";
    case "Pending":
      return "orange";
    case "BcaApproval":
      return "green";
    case "BcaRejection":
      return "red";
    case "Positive":
      return "green";
    case "Negative":
      return "red";
    case "Recommended":
      return "yellow";
    case "Not Recommended":
      return "#859bed";
    case "Failed":
      return "red";
    case "Could Not Verified":
      return "red";
    default:
      return "yellow";
  }
};

const formatDate = (dateString) => {
  let date;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const VleaderEmploymentDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [experienceInfo, setExperienceInfo] = useState([]);

  const getExperienceInfo = (id) => {
    ApiService.get(`experienceCheck/${id}`)
      .then((res) => {
        setExperienceInfo(res?.data?.docs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getExperienceInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (experienceInfo.length > 0) {
      onDataUpdate({
        experienceInfo,
        renderExperienceDetails: () => renderExperienceDetails(),
      });
    }
  }, [experienceInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      <Link href={imgSrc} className={className || ""} target="_blank">
        {alt || "Click here to view the image"}
      </Link>
    );
  };

  const renderExperienceDetails = () => {
    return (
      <>
        {experienceInfo?.length > 0 && (
          <>
            {experienceInfo.map((experienceEvidence, index) => (
              <React.Fragment key={index}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: 5,
                    marginTop: 30,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  >
                    Previous Employment Verification:
                  </Text>

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        marginRight: 5,
                      }}
                    >
                      Check Status:
                    </Text>
                    <View
                      style={{
                        backgroundColor: getStatusColor(
                          experienceEvidence?.experience?.status
                        ),
                        paddingVertical: 2,
                        paddingHorizontal: 10,
                        borderRadius: 4,
                      }}
                    >
                      <Text
                        style={{
                          color: "black",
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                      >
                        {experienceEvidence?.experience?.status}
                      </Text>
                    </View>
                  </View>
                </View>
                <View key={index} style={EmploymentCheckStyles.table}>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "bold",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Criteria
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "bold",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Provided Information
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "bold",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Verified Information
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Employer & Address
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.officeAddress}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.verificationDetails
                        ?.officeAddressRemark ||
                        experienceEvidence?.experience?.officeAddress}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Employee Code
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.employeeCode}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.verificationDetails
                        ?.employeeCodeRemark ||
                        experienceEvidence?.experience?.employeeCode}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Period of Employment
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.employmentStartDates &&
                      experienceEvidence?.experience?.employmentEndDates
                        ? `${formatDate(experienceEvidence?.experience?.employmentStartDates)} To ${formatDate(experienceEvidence?.experience?.employmentEndDates)}`
                        : "NA"}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.verificationDetails
                        ?.employmentStartDatesRemark &&
                      experienceEvidence?.verificationDetails
                        ?.employmentEndDatesRemark
                        ? `${formatDate(experienceEvidence?.verificationDetails?.employmentStartDatesRemark)} To ${formatDate(experienceEvidence?.verificationDetails?.employmentEndDatesRemark)}`
                        : experienceEvidence?.experience
                              ?.employmentStartDates &&
                            experienceEvidence?.experience?.employmentEndDates
                          ? `${formatDate(experienceEvidence?.experience?.employmentStartDates)} To ${formatDate(experienceEvidence?.experience?.employmentEndDates)}`
                          : "NA"}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Designation
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.designation}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.verificationDetails
                        ?.designationRemark ||
                        experienceEvidence?.experience?.designation}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Salary
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.salary}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.verificationDetails?.salaryRemark ||
                        experienceEvidence?.experience?.salary}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Reporting Manager
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.supervisorName}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.verificationDetails
                        ?.supervisorNameRemark ||
                        experienceEvidence?.experience?.supervisorName}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Type of Employment Full Time/ Part Time
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.typeOfEmployment}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.verificationDetails
                        ?.typeOfEmploymentRemark ||
                        experienceEvidence?.experience?.typeOfEmployment}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Reason for Leaving
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.reasonForLeaving}
                    </Text>
                    <Text
                      style={{
                        width: "200px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.verificationDetails
                        ?.reasonForLeavingRemark ||
                        experienceEvidence?.experience?.reasonForLeaving}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Full & Final Formalities (Clear/Pending)
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {
                        experienceEvidence?.experience
                          ?.fullAndFinalResponsibilities
                      }
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Any Disciplinary /Performance Issues: (Please Specify)
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.performanceIssues}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Pending Dues- If Any
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.candidateDues}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Eligible for Rehire (If No, Please Specify the reason)
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.rehire}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Mode of Verification
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.modeOfVerification}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Additional Remarks
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.additionalRemarks}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Verified By : (Please specify Name, Designation and
                      Contact No)
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.verifiedBy}
                    </Text>
                  </View>
                  <View style={EmploymentCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "300px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Proof
                    </Text>
                    <Text
                      style={{
                        width: "600px",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {experienceEvidence?.experience?.uploadProofUrl?.map(
                        (url, proofIndex) => {
                          return (
                            <Text key={proofIndex} style={{ marginRight: 5 }}>
                              <AsyncImage
                                fileUrl={url}
                                alt={`Experience evidence ${proofIndex + 1}`}
                              />
                              {proofIndex <
                                experienceEvidence.experience.uploadProofUrl
                                  .length -
                                  1 && (
                                <Text style={{ marginRight: 5 }}> , </Text>
                              )}
                            </Text>
                          );
                        }
                      )}
                    </Text>
                  </View>
                </View>
                <View break />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };
  return null;
};
