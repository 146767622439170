import React, { useEffect, useState } from "react";
import ApiService from "../../services/APIServices";
// import { useNavigate } from "react-router-dom";
import NoData from "../../Helper/NoDataFound.jsx";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useTable, useRowSelect, useSortBy } from "react-table";
import Icons from "../../Helper/Icons.jsx";
import { PieChart } from "@mui/x-charts/PieChart";
import { ProgressSpinner } from "primereact/progressspinner";

const ExecutiveLogs = (user) => {
  console.log("USER>>", user?.user);

  //   const navigate = useNavigate();
  //   const [staffMemberInfo, setStaffMemberInfo] = useState("");
  const [taskList, setTaskList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("InProgress");
  const [totalData, setTotalData] = useState();
  const [completedData, setCompletedData] = useState();
  const [inProgressData, setInProgressData] = useState();
  const [page, setPage] = useState(1);
  const [spinner, setSpinner] = useState(true);
  const [totalPages, setTotalPages] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
        Cell: ({ row }) => {
          return (
            <div
              role="presentation"
              //   onClick={() =>
              //     navigate("/assigned-task-page", {
              //       state: {
              //         taskList: row?.original,
              //       },
              //     })
              //   }
              className="flex align-items-center gap-2 p-2 rounded"
            >
              <span>
                {row?.original?.userVerificationRequest?.userInfo
                  ?.firstName || <span>NA</span>}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        Cell: ({ row }) => {
          return (
            <div>
              {row?.original?.userVerificationRequest?.userInfo?.lastName || (
                <span>NA</span>
              )}
            </div>
          );
        },
      },
      {
        Header: "Company Name",
        accessor: "name",
        Cell: ({ row }) => {
          return (
            <div>
              {row?.original?.userVerificationRequest?.companyInfo?.name || (
                <span>NA</span>
              )}
            </div>
          );
        },
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
        Cell: ({ row }) => {
          return (
            <div>
              {row?.original?.userVerificationRequest?.userInfo
                ?.phoneNumber || <span>NA</span>}
            </div>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ row }) => {
          return (
            <div>
              {row?.original?.userVerificationRequest?.userInfo?.email || (
                <span>NA</span>
              )}
            </div>
          );
        },
      },
      {
        Header: "Check Name",
        accessor: "checkListKey",
        Cell: ({ row }) => {
          // Conditionally apply background color based on the selectedStatus state
          const bgColor =
            selectedStatus === "InProgress" ? " bg-[#FFBD51]" : "bg-[#69BE6D]";
          return (
            <div className={`${bgColor} p-2 rounded`}>
              {row?.original?.checkListKey || <span>NA</span>}
            </div>
          );
        },
      },
    ],
    [selectedStatus]
  );

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data: taskList },
    useSortBy,
    useRowSelect
  );

  const data = [
    { label: "Complete Cases", value: completedData, color: "#69BE6D" },
    { label: "Ongoing Cases", value: inProgressData, color: "#FFBD51" },
  ];

  //   useEffect(() => {
  //     setStaffMemberInfo(user);
  //   }, []);
  useEffect(() => {
    if (user?.user !== null && user?.user._id !== undefined) {
      getStaffMemberTask();
    }
  }, [user?.user, selectedStatus, page]);

  useEffect(() => {
    // taskList;
  }, [taskList]);

  let queryParams = `?limit=4&page=${page}`;
  if (selectedStatus) {
    queryParams += `&status=${selectedStatus}`;
  }
  const getStaffMemberTask = () => {
    setSpinner(true);
    ApiService.get(`task/staffId/${user?.user._id}${queryParams}`)
      .then((res) => {
        setSpinner(false);
        setTaskList(res?.data?.docs);
        console.log("TASKLIST", res?.data?.docs);

        setTotalPages(res.data.totalPages);
        // setLoading(false);
      })
      .catch((err) => console.log(err, "{{TASKERR}}"));
  };

  const getStatusCount = () => {
    // let BCAStaff = JSON.parse(localStorage.getItem("user"));
    ApiService.get(`task/status/count/${user?.user._id}`)
      .then((log) => {
        setInProgressData(log?.data?.inProgress);
        setCompletedData(log?.data?.completed);
        setTotalData(log?.data?.totalDocs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getStatusCount();
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <div className="container px-auto mx-auto bg-white">
        <div className="flex flex-row gap-[4px]">
          <span className="text-black text-2xl font-normal">
            Activity status of Verification-Executive{" "}
          </span>
          <span className="bg-gradient-to-r from-sky-800 to-teal-400 text-2xl font-semibold font-['Poppins'] text-transparent bg-clip-text mt-[2px]">
            {user?.user.firstName} {user?.user.lastName}
          </span>
        </div>
        <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 mt-2">
          <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-2">
            <div
              role="presentation"
              onClick={() => setSelectedStatus("InProgress")}
              className="flex flex-row p-3 border-2 shadow-md rounded-[10px] justify-between w-[250px] h-[120px] mx-auto cursor-pointer hover:bg-gray-100"
            >
              <div className="flex flex-col">
                <p className="text-[17px] font-medium !mb-0 max-sm:h-[75px]">
                  InProgress
                  <br />
                  Task
                </p>
                <h2 className="!text-[#3E74DB] !mb-0">{inProgressData}</h2>
              </div>
              <Icons name="pending-case-icon" />
            </div>
            <div
              role="presentation"
              onClick={() => setSelectedStatus("Completed")}
              className="flex flex-row p-3 border-2 shadow-md rounded-[10px] justify-between w-[250px] h-[120px] mx-auto cursor-pointer hover:bg-gray-100"
            >
              <div className="flex flex-col">
                <p className="text-[17px] font-medium !mb-0 max-sm:h-[75px]">
                  Completed
                  <br />
                  Task
                </p>
                <h2 className="!text-[#3E74DB] !mb-0">{completedData}</h2>
              </div>
              <Icons name="complete-icon" />
            </div>
            <div className="flex flex-row p-3 border-2 shadow-md rounded-[10px] justify-between w-[250px] h-[120px] mx-auto">
              <div className="flex flex-col">
                <p className="text-[17px] font-medium !mb-0 max-sm:h-[75px]">
                  Re-Work <br />
                  Task{" "}
                </p>
                <h2 className="!text-[#3E74DB] !mb-0">0</h2>
              </div>
              <Icons name="ongoing-icon" />
            </div>
            <div className="flex flex-row p-3 border-2 shadow-md rounded-[10px] justify-between w-[250px] h-[120px] mx-auto">
              <div className="flex flex-col">
                <p className="text-[17px] font-medium !mb-0 max-sm:h-[75px]">
                  Assigned <br />
                  Task{" "}
                </p>
                <h2 className="!text-[#3E74DB] !mb-0">{totalData}</h2>
              </div>
              <Icons name="report-case-icon" />
            </div>
          </div>

          <div className="shadow-lg bg-white p-4 lg:w-full border-2">
            <PieChart
              series={[
                {
                  data: data,
                  cx: 100,
                  cy: 100,
                  innerRadius: 40,
                  outerRadius: 80,
                },
              ]}
              height={200}
              slotProps={{
                legend: {
                  hidden: isMobile,
                },
              }}
            />
          </div>
        </div>

        {/* <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 max-sm:gap-[35px] gap-[90px] mt-[10px]">
          <div
            role="presentation"
            onClick={() => setSelectedStatus("InProgress")}
            className="flex flex-row p-3 border-2 shadow-md rounded-[10px] justify-between w-[230px] h-[150px] mx-auto cursor-pointer hover:bg-gray-100"
          >
            <div className="flex flex-col">
              <p className="text-[17px] font-medium !mb-0 max-sm:h-[75px]">
                InProgress
                <br />
                Task
              </p>
              <h2 className="!text-[#3E74DB] !mb-0">{inProgressData}</h2>
            </div>
            <Icons name="pending-case-icon" />
          </div>
          <div
            role="presentation"
            onClick={() => setSelectedStatus("Completed")}
            className="flex flex-row p-3 border-2 shadow-md rounded-[10px] justify-between w-[230px] h-[150px] mx-auto cursor-pointer hover:bg-gray-100"
          >
            <div className="flex flex-col">
              <p className="text-[17px] font-medium !mb-0 max-sm:h-[75px]">
                Completed Task
              </p>
              <h2 className="!text-[#3E74DB] !mb-0">{completedData}</h2>
            </div>
            <Icons name="complete-icon" />
          </div>
          <div className="flex flex-row p-3 border-2 shadow-md rounded-[10px] justify-between w-[230px] h-[150px] mx-auto">
            <div className="flex flex-col">
              <p className="text-[17px] font-medium !mb-0 max-sm:h-[75px]">
                Assigned <br />
                Task{" "}
              </p>
              <h2 className="!text-[#3E74DB] !mb-0">{totalData}</h2>
            </div>
            <Icons name="report-case-icon" />
          </div>
          <div className="bg-white border-2 w-[400px] max-sm:w-[300px] rounded">
            <PieChart
              series={[
                {
                  data: data,
                  cx: 100,
                  cy: 100,
                  innerRadius: 40,
                  outerRadius: 80,
                },
              ]}
              height={200}
              slotProps={{
                legend: {
                  hidden: isMobile,
                },
              }}
            />
          </div>
        </div> */}
        <div className="flex md:flex-row flex-col justify-between items-center mt-[20px]">
          <h6>Assigned Tasks from Admin</h6>
          <div className="flex flex-row gap-[28px]">
            <button
              onClick={() => setSelectedStatus("InProgress")}
              className={`px-4 py-2 rounded ${selectedStatus === "InProgress" ? "bg-[#FFBD51]" : "bg-gray-200"}`}
            >
              INPROGRESS
            </button>
            <button
              onClick={() => setSelectedStatus("Completed")}
              className={`px-4 py-2 rounded ${selectedStatus === "Completed" ? "bg-[#69BE6D]" : "bg-gray-200"}`}
            >
              COMPLETED
            </button>
          </div>
        </div>
        <div className="mt-[20px]">
          <div className="w-[100px] text-slate-800 text-base font-semibold ">
            {selectedStatus.toUpperCase()}
          </div>
          {selectedStatus === "InProgress" ? (
            <div className="w-full h-0.5 bg-[#FFBD51] rounded-[50px] mt-[10px]" />
          ) : selectedStatus === "Completed" ? (
            <div className="w-full h-0.5 bg-[#69BE6D] rounded-[50px] mt-[10px]" />
          ) : (
            ""
          )}
        </div>

        <div className=" shadow p-[30px]  overflow-x-auto ">
          <div className="inline-block min-w-full  align-middle ">
            <div className="justify-between flex flex-row">
              {" "}
              <div className=" text-black text-[22px] font-semibold ">
                Task&apos;s List
              </div>
              {/* <div className="flex flex-row gap-[5px] ">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    className="h-[39px] max-sm:w-[10rem]"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    placeholder="Search by Name"
                  />
                </span>
              </div> */}
            </div>

            <div className="w-full mt-[20px]  flex md:flex md:overflow-x-auto lg:overflow-x-visible md:rounded-lg">
              <table className="mt-2 min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className="bg-white">
                  {headerGroups.map((headerGroup) => (
                    <tr
                      className="px-6 py-4"
                      key={Math.random().toString(36).substr(2, 9)}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column) => (
                        <th
                          className="py-3.5 px-4 text-gray-400 text-sm font-medium rtl:text-right text-nowrap "
                          key={Math.random().toString(36).substr(2, 9)}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ""
                                : ""
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900"
                  {...getTableBodyProps()}
                >
                  {spinner ? (
                    <tr>
                      <td colSpan={columns?.length}>
                        <div className="text-center mt-1">
                          <ProgressSpinner
                            style={{ width: "50px", height: "50px" }}
                            strokeWidth="8"
                            animationDuration=".7s"
                          />
                        </div>
                      </td>
                    </tr>
                  ) : taskList?.length === 0 ? (
                    <tr>
                      <td colSpan={columns?.length}>
                        <NoData info="Ooops, we could not find any case" />
                      </td>
                    </tr>
                  ) : (
                    rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap"
                          key={Math.random().toString(36).substr(2, 9)}
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td
                                className="px-4 py-4 text-zinc-800 text-sm font-medium whitespace-nowrap"
                                key={Math.random().toString(36).substr(2, 9)}
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex justify-center mt-4 mb-4">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  onChange={handleChange}
                  variant="outlined"
                  shape="rounded"
                  color="primary"
                  page={page}
                />
              </Stack>
            </div>
          </div>
        </div>

        {/* {loading ? (
          <div className="text-center mt-20">
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="8"
              animationDuration=".7s"
            />
          </div>
        ) : taskList.length > 0 ? (
          <div className="w-full mt-[20px] grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-[6%] sm:gap-4">
            {taskList
              .filter((task) => task.status === selectedStatus)
              .map((item) => (
                <div
                  role="presentation"
                  key={item.id} // Ensure unique key
                  className="w-full lg:w-[250px] h-[180px] mt-[10px] bg-white rounded-lg shadow p-[18px] cursor-pointer"
                  onClick={
                    staffMemberInfo.hasStaffAccess
                      ? () =>
                          navigate("/assigned-task-page", {
                            state: {
                              taskList: item,
                            },
                          })
                      : null
                  }
                >
                  <div className="w-[218px] h-[90px] flex-col gap-[5px] inline-flex">
                    <div className="text-slate-800 text-sm font-semibold ">
                      {process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED ===
                      "true" ? (
                        item?.userVerificationRequest?.userInfo?.clientName
                      ) : (
                        <>
                          {item?.userVerificationRequest?.userInfo?.firstName}{" "}
                          {item?.userVerificationRequest?.userInfo?.lastName}
                        </>
                      )}
                    </div>
                    <div className="w-[217px] text-slate-400 text-xs font-normal ">
                      {item?.userVerificationRequest?.companyInfo?.name}
                    </div>
                    <div className="w-[217px] text-slate-400 text-xs font-normal ">
                      {item?.userVerificationRequest?.userInfo?.phoneNumber}
                    </div>
                    <div className="w-[217px] text-slate-400 text-xs font-normal ">
                      {item?.userVerificationRequest?.userInfo?.email}
                    </div>
                  </div>
                  {selectedStatus === "InProgress" ? (
                    <div className="w-[150px] mt-[12px] h-6 p-2 bg-[#FFBD51] rounded justify-center items-center gap-2.5 inline-flex">
                      <div className="text-black-500 text-xs font-medium ">
                        {item?.checkListKey}
                      </div>
                    </div>
                  ) : selectedStatus === "Completed" ? (
                    <div className="w-[150px] mt-[12px] h-6 p-2 bg-[#69BE6D] rounded justify-center items-center gap-2.5 inline-flex">
                      <div className="text-black-500 text-xs font-medium ">
                        {item?.checkListKey}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))}
          </div>
        ) : (
          <div className="w-full">
            <NoData info="NO RECORD FOUND" />
          </div>
        )} */}
      </div>
      {/* <div className="flex justify-center lg:mt-4 mt-5 mb-4">
        <Stack spacing={2}>
          <Pagination
            count={totalPages}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            color="primary"
            page={page}
          />
        </Stack>
      </div> */}
    </div>
  );
};

export default ExecutiveLogs;
