import React, { useState, useEffect } from "react";
import ApiService from "../../services/APIServices";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
// import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Inefficiency from "../UserProfileView/Checklist/Inefficiency.jsx";
import { getSeverity } from "../../utils/constants.js";
import { ValidateInput } from "../../Helper/ValidateInput.jsx";
import { SplitButton } from "primereact/splitbutton";
import { getBase64 } from "../../Helper/Base64File";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";
import { customItemTemplate } from "../../Helper/UploadFileTemplate.jsx";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import NoData from "../../Helper/NoDataFound.jsx";

const BankDetails = ({
  userVerificationDetail,
  userVerificationRequestInfo,
  taskDetail,
  bankCheckInfo,
  getBankCheckInfo,
  activityDetails,
  showActivity,
  showReWork,
  getActivityDetails,
  flag,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  //   const categories = [{ name: "Friends/Colleague" }, { name: "Others" }];
  // const [editable, setEditable] = useState(false);
  const [fileUpload, SetFileUplaod] = useState([]);
  const [uploadProof, SetUploadProof] = useState([]);
  const [fileMap, setFileMap] = useState([]);
  const [proofImage, setProofImage] = useState([]);
  const [timer, setTimer] = useState(null);
  const [activity, setActivity] = useState("");
  const SIGNEDURL_EXPIRATION_TIME =
    process.env.REACT_APP_SIGNEDURL_EXPIRATION_TIME;

  let localStorageData = JSON.parse(localStorage.getItem("user"));

  const updateCaseIsQCOpen = async (flag) => {
    let body = {
      status: flag,
    };
    ApiService.post(`userVerificationRequest/${userVerificationDetail}`, body)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const confirm1 = (id) => {
    if (localStorageData?.role === "user") {
      setIsModalOpen(true);
    }
    confirmDialog({
      message: (
        <div className="flex items-center pt-[17px]">
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          <span className="pt-[2px]">Are you sure you want to DELETE?</span>
        </div>
      ),
      header: "Confirmation",
      accept: () => accept(id),
      // reject: () => setDialogVisible(false),
      // onHide: () => setDialogVisible(false),
    });
  };

  const accept = (id) => {
    deleteItem(id);
  };

  const deleteItem = (id) => {
    const url = `bankCheck/remove/${id}`;

    ApiService.post(url)
      .then(() => {
        // setDialogVisible(false);
        toast.success("Item Deleted Successfully");
        getBankCheckInfo(userVerificationDetail);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Delete Item");
      });
  };

  const getImg = (e, fileName, dataIndex, reportIndex, imgFlag) => {
    e.preventDefault();
    // Clear the existing timer if any
    if (timer) {
      clearTimeout(timer);
    }

    ApiService.get(`download/${fileName}`)
      .then((res) => {
        if (imgFlag === "img") {
          setFileMap((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
          // const newDownloadState = downloadState.map((state, i) =>
          //   i === index ? !state : state
          // );
          // setDownloadState(newDownloadState);
        } else {
          setProofImage((prev) => ({
            ...prev,
            [dataIndex]: { ...prev[dataIndex], [reportIndex]: res?.data },
          }));
        }
        const newTimer = setTimeout(clearImages, SIGNEDURL_EXPIRATION_TIME); // 2 minutes
        setTimer(newTimer);
        toast.success("Document download Sucessfully ");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Image Download");
      });
  };

  const handleFileUpload = async (event, category) => {
    const allowedExtensions = /.(png|jpeg|pdf)$/i;
    const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE, 10);
    const files = event.files;
    const fileData = [];

    for (let file of files) {
      // Split the filename by dots to check for multiple extensions
      const fileNameParts = file.name.split(".");

      // Validate file extension and check for multiple extensions
      if (!allowedExtensions.exec(file.name) || fileNameParts.length > 2) {
        toast.error(
          "Invalid file type. Only files with '.png','.jpeg', and '.pdf' extensions are allowed."
        );
        return;
      }

      if (file.size > maxFileSize) {
        toast.error("Please choose a valid file size.");
        return;
      }

      const result = await new Promise((resolve) => {
        getBase64(file, (base64String) => resolve(base64String));
      });
      fileData.push({ path: result, name: file.name });
    }

    try {
      const res = await ApiService.post("multiUpload", {
        files: fileData,
      });
      const uploadedFileUrls = res.data.map((item) => item.url);

      switch (category) {
        case "file":
          SetFileUplaod(uploadedFileUrls);
          toast.success("File successfully uploaded");
          break;
        case "uploadProof":
          SetUploadProof(uploadedFileUrls);
          toast.success("Upload proof successfully uploaded");
          break;
        default:
          break;
      }
    } catch (error) {
      console.log("Error uploading files: ", error);
      toast.error("Error uploading file. Please try again.");
      // Handle error uploading files
    }
  };

  // const handleEdit = () => {
  //   setEditable(!editable);
  // };

  const clearImages = () => {
    setFileMap([]);
    setProofImage([]);
    toast.warning("Link expired, please download again.");
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const createActivity = async (action, caseId) => {
    let body = {
      userVerificationRequestId: caseId,
      // checkId: checkId,
      FEName:
        localStorageData?.role === "BCA"
          ? localStorageData?.name
          : localStorageData?.role === "BCAStaff"
            ? `${localStorageData?.firstName} ${localStorageData?.lastName}`
            : localStorageData?.role === "Coordinator"
              ? localStorageData?.vendorName
              : localStorageData?.role === "DataEntry"
                ? localStorageData?.vendorName
                : "",
      checkName: "Bank",
      description: action,
      activityDetails: {
        action: action,
      },
    };
    await ApiService.post("caseActivity", body)
      .then(() => {
        getActivityDetails(userVerificationDetail);
        setActivity("");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Create Activity");
      });
  };

  const getBankInfo = (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "bankCheck/link";
    } else {
      url = "bankCheck";
    }
    ApiService.get(`${url}/${id}`)
      .then(() => {
        // res?.data?.docs[0]; // Assuming there's only one item in the array
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const updateTaskStatus = async () => {
  //   let body = {
  //     status: "Completed",
  //   };
  //   await ApiService.post(`task/status/${taskDetail?._id}`, body)
  //     .then(() => {
  //       toast.success("Task Submitted Successfully");
  //       navigate("/staff-member-dashboard");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  const handleSaveClick = (e, id, index) => {
    e.preventDefault();
    updateBankDetails(e, id, index);
    handleTaskForReWork();
  };

  const updateTaskStatus = async (taskId, status, flag) => {
    let body = {
      status: status,
    };
    await ApiService.post(`task/status/${taskId}`, body)
      .then(() => {
        if (status === "Completed") {
          toast.success("Task Submitted Successfully");
          updateCaseIsQCOpen(flag);
          navigate("/staff-member-dashboard");
        } else if (status === "InProgress") {
          updateCaseIsQCOpen(flag);
          // navigate("/cases");
          toast.success("Task Updated Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error In Task Update");
      });
  };

  const handleSubmitTask = () => {
    updateTaskStatus(taskDetail?._id, "Completed", "QC-Open");
  };

  const handleTaskForReWork = () => {
    updateTaskStatus(userVerificationRequestInfo, "InProgress", "InProgress");
  };

  const removeImg = (e, id, item, reportIndex, type) => {
    e.preventDefault();

    if (type === "file") {
      const updatedUrls = item?.bank?.reportUrl.filter(
        (_, index) => index !== reportIndex
      );
      updateBankFileArray(e, id, item, updatedUrls);
    } else {
      const updatedUrls = item?.bank?.uploadProofUrl.filter(
        (_, index) => index !== reportIndex
      );
      updateBankProofArray(e, id, item, updatedUrls);
    }
  };

  const updateBankFileArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "bankCheck/link";
    } else {
      url = "bankCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      bank: {
        ...item.bank,
        reportUrl: updatedReportUrls, // Set the updated reportUrl array here
        remark: item.bank.remark,
        uploadProofUrl: [
          ...new Set([...item.bank.uploadProofUrl, ...uploadProof]),
        ],
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getBankInfo(userVerificationDetail);
      toast.success("Bank details updated successfully");
      SetFileUplaod([]); // Reset file upload state
      SetUploadProof([]); // Reset proof upload state
      if (flag === "false") {
        getBankCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateBankProofArray = async (e, id, item, updatedReportUrls) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "bankCheck/link";
    } else {
      url = "bankCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      bank: {
        ...item.bank,
        reportUrl: [...new Set([...item.bank.reportUrl, ...fileUpload])], // Set the updated reportUrl array here
        remark: item.bank.remark,
        uploadProofUrl: updatedReportUrls,
      },
      verificationDetails: {
        ...item.verificationDetails,
      },
      location: {
        ...item.location,
      },
    };

    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getBankInfo(userVerificationDetail);
      toast.success("Bank details updated successfully");
      SetFileUplaod([]); // Reset file upload state
      SetUploadProof([]); // Reset proof upload state
      if (flag === "false") {
        getBankCheckInfo(userVerificationDetail);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const onInputChange = (e, index, type) => {
    const { value } = e.target;
    if (ValidateInput(value, type)) {
      setData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          bank: {
            ...newData[index].bank,
            [e.target.name]: e.target.value,
          },
          verificationDetails: {
            ...newData[index].verificationDetails,
            [e.target.name]: e.target.value,
          },
        };
        // Return the updated array
        return newData;
      });
    }
  };
  //   const selectedCountryTemplate = (option, props) => {
  //     if (option) {
  //       return (
  //         <div className="flex align-items-center">
  //           <div>{option.name}</div>
  //         </div>
  //       );
  //     }

  //     return <span>{props.placeholder}</span>;
  //   };

  //   const countryOptionTemplate = (option) => {
  //     return (
  //       <div className="flex align-items-center">
  //         <div>{option.name}</div>
  //       </div>
  //     );
  //   };

  const handleEdit = () => {
    setEditable(!editable);
  };

  useEffect(() => {
    setData(bankCheckInfo);
  }, [bankCheckInfo]);

  const updateBankDetails = async (e, id, index) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "bankCheck/link";
    } else {
      url = "bankCheck";
    }
    e.preventDefault();
    setLoading(true);
    const body = {
      userVerificationRequestId: userVerificationDetail,
      bank: {
        ...data[index]?.bank,
        reportUrl: [
          ...new Set([...data[index]?.bank?.reportUrl, ...fileUpload]),
        ],
        remark: data[index]?.bank?.remark,
        uploadProofUrl: [
          ...new Set([...data[index]?.bank?.uploadProofUrl, ...uploadProof]),
        ],
      },
      verificationDetails: {
        ...data[index]?.verificationDetails,
      },
      // bank: data[index]?.bank,
    };
    try {
      await ApiService.post(`${url}/${id}`, body);
      setLoading(false);
      getBankInfo(userVerificationDetail);
      toast.success("Bank details updated successfully");
      getBankCheckInfo(userVerificationDetail);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const updateCheckStatus = async (status, id) => {
    // e.preventDefault();
    const body = { status: status };
    try {
      await ApiService.post(`bankCheck/bank/status/${id}`, body);

      toast.success("Check Task updated!");
      // updateTaskItem(id, status);
      getBankCheckInfo(userVerificationDetail);
      // setCheckStatus(status);
      // geteducationCheckInfo(userVerificationDetail);
      // navigate("/staff-member-dashboard");
    } catch (error) {
      console.error("Error In Task Update", error);
      toast.error("Error In Task Update");
    }
  };
  // const updateTaskItem = async (id, status) => {
  //   let body = {
  //     status: status,
  //     _id: id,
  //     objectName: "bank",
  //   };
  //   await ApiService.post(`task/item/${taskDetail?._id}`, body)
  //     .then(() => {})
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Error In Task Update");
  //     });
  // };

  return (
    <>
      {flag === "true" && taskDetail?.status !== "Completed" ? (
        <div className="text-end mb-[10px]">
          <Button
            label="Submit Task"
            type="button"
            onClick={() => {
              createActivity(
                "Bank Task Submitted!",
                userVerificationDetail,
                bankCheckInfo[0]._id
              );
              handleSubmitTask();
            }}
            className="w-[150px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
          />
        </div>
      ) : (
        <></>
      )}

      {showReWork &&
        data?.map((item, dataIndex) =>
          dataIndex === 0 ? (
            <div
              key={dataIndex}
              className="border rounded shadow-md my-4 bg-[#fafafa]"
            >
              <div className="grid items-center sm:grid-cols-3 gap-x-4 mt-2 p-3">
                <div className="col-span-2">
                  <label
                    className="small mb-1 font-bold"
                    htmlFor="reWorkRemark"
                  >
                    Remark For Re-Work
                  </label>
                  <InputTextarea
                    className="form-control"
                    type="text"
                    name="reWorkRemark"
                    onChange={(e) =>
                      onInputChange(
                        e,
                        dataIndex,
                        "alphanumericWithAllowedSymbols"
                      )
                    }
                    value={item?.bank?.reWorkRemark || ""}
                    // disabled={
                    //   flag === "true" || localStorageData?.role === "BCAStaff"
                    // }
                    rows={5}
                    cols={30}
                  />
                </div>
                {taskDetail?.status !== "InProgress" ? (
                  <div className="mt-[26px]">
                    <Button
                      className="w-[100px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      label="Submit"
                      type="submit"
                      loading={loading}
                      onClick={(e) => handleSaveClick(e, item._id, dataIndex)}
                      disabled={
                        flag === "true" || localStorageData?.role === "BCAStaff"
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="px-3 py-4 mt-4 bg-white shadow-md rounded">
                <label
                  className="small mb-1 font-bold"
                  htmlFor="submittedRemark"
                >
                  Submitted Remark:
                </label>
                <p className="text-gray-800 mt-2">
                  {item.bank?.reWorkRemark || "No remark submitted"}
                </p>
              </div>
            </div>
          ) : null
        )}

      {showActivity && (
        <div className="border  rounded shadow-md my-4 bg-[#fafafa]">
          <div className="grid items-center sm:grid-cols-3 gap-x-4 mt-2 p-3 ">
            <div className="col-span-2">
              <label className="small mb-1 font-bold" htmlFor="activity">
                Activity
              </label>
              <textarea
                className="form-control h-[60px] "
                name="activity"
                onChange={(e) => {
                  setActivity(e.target.value);
                  // onInputChange(e, dataIndex, "alphanumeric");
                }}
                value={activity || ""}
              />
            </div>
            <div className="mt-[26px]">
              <Button
                label="Save"
                type="submit"
                loading={loading}
                onClick={(e) => {
                  e.preventDefault();
                  createActivity(
                    activity,
                    userVerificationDetail
                    // item?._id
                  );
                }}
                className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              />
            </div>
          </div>
          <div className="">
            <label className="small mb-1 px-3   font-bold" htmlFor="activity">
              All Activities List
            </label>
            <div className="h-[310px] p-3 overflow-y-scroll">
              <List
                sx={{
                  width: "100%",
                  // maxWidth: 450,
                  bgcolor: "#fafafa",
                }}
              >
                {activityDetails.length === 0 ? (
                  <>
                    <div className="flex items-center lg:ml-[50px] mt-[30px]">
                      <NoData info="Ooops, we could not find any activity" />
                    </div>
                  </>
                ) : (
                  <>
                    {activityDetails.map((item) => {
                      const createdAt = new Date(item?.createdAt);
                      const formattedDate =
                        createdAt.toLocaleDateString("en-GB");
                      const formattedTime =
                        createdAt.toLocaleTimeString("en-GB");

                      return (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            className="border rounded !mb-3 shadow-md bg-[#ffffff]"
                          >
                            <ListItemAvatar>
                              <Avatar
                                alt="Remy Sharp"
                                src="http://bootdey.com/img/Content/avatar/avatar1.png"
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Box className="flex flex-wrap item-center justify-between wrap">
                                  <Typography
                                    component="span"
                                    sx={{ fontWeight: "600" }} // Apply bold style here
                                    className="text-black text-wrap"
                                  >
                                    {item.FEName}
                                  </Typography>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    className="!font-bold text-black mt-1"
                                  >
                                    {formattedDate} {formattedTime}
                                  </Typography>
                                </Box>
                              }
                              className="!font-bold text-black"
                              secondary={
                                <React.Fragment>
                                  <Box
                                    component="span"
                                    sx={{
                                      display: "inline",
                                      color: "blue-300",
                                      marginRight: 1,
                                    }}
                                  >
                                    <Typography
                                      component="span"
                                      sx={{ color: "green" }}
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      Activity -
                                    </Typography>
                                  </Box>
                                  {item?.description}
                                  <br />
                                  {/* <Typography
                                sx={{ display: "inline", color: "green" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {item?.activityDetails?.action}
                              </Typography> */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          {/* <Divider variant="inset" component="li" /> */}
                        </>
                      );
                    })}
                  </>
                )}
              </List>
            </div>
          </div>{" "}
        </div>
      )}

      {data?.map((item, index) => (
        <>
          <Card
            title={item?.qualification}
            className={`sm:border-2 ${index === 0 ? "mt-0" : "mt-4"} relative`}
          >
            {localStorageData?.role !== "BCAStaff" && (
              <div
                className=" text-end cursor-pointer absolute right-[-11px] top-[-13px] "
                role="presentation"
                onClick={() => confirm1(item._id)}
              >
                <DeleteIcon
                  style={{ color: "red", height: "35px", width: "35px" }}
                />
                {/* <ConfirmDialog /> */}
              </div>
            )}
            <div className="flex flex-col justify-end">
              {flag === "true" && (
                <>
                  <div className="flex flex-row justify-between mb-[10px]">
                    <div className="flex justify-between">
                      {process.env.REACT_APP_SHOW_VERIFYKART_COLUMNS ===
                        "true" && (
                        <div className="flex flex-row gap-1">
                          <div
                            role="presentation"
                            onClick={(e) => {
                              createActivity(
                                "Pending -> Approved",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Approved",
                                e,
                                item?._id,
                                index
                              );
                            }}
                          >
                            <Button
                              label="Approve"
                              severity="success"
                              icon="pi text-white pi-check-circle"
                              size="small"
                            />
                          </div>
                          <div
                            role="presentation"
                            onClick={(e) => {
                              createActivity(
                                "Pending -> Rejected",
                                item?.userVerificationRequestId,
                                item?._id
                              );
                              updateCheckStatus(
                                "Rejected",
                                e,
                                item?._id,
                                index
                              );
                            }}
                          >
                            <Button
                              label="Reject"
                              icon="pi text-white pi-times-circle"
                              severity="danger"
                              size="small"
                            />
                          </div>
                        </div>
                      )}

                      {process.env.REACT_APP_SHOW_PAMAC_COLUMNS === "true" && (
                        <SplitButton
                          label="Update Status"
                          icon="pi pi-check"
                          // onClick={(e) => {
                          //   // Do nothing, the menu items will handle the status update
                          // }}
                          model={[
                            {
                              label: "Major Discrepancy",
                              command: () => {
                                createActivity(
                                  "Pending -> Major Discrepancy",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );

                                updateCheckStatus(
                                  "Major Discrepancy",
                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Minor Discrepancy",
                              command: () => {
                                createActivity(
                                  "Pending -> Minor Discrepancy",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );

                                updateCheckStatus(
                                  "Minor Discrepancy",
                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Verified Clear",
                              command: () => {
                                createActivity(
                                  "Pending -> Verified Clear",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Verified Clear",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Insufficiency",
                              command: () => {
                                createActivity(
                                  "Pending -> Insufficiency",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Insufficiency",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Unable to Verify",
                              command: () => {
                                createActivity(
                                  "Pending -> Unable to Verify",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Unable to Verify",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Stop Check",
                              command: () => {
                                createActivity(
                                  "Pending -> Stop Check",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Stop Check",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Positive",
                              command: () => {
                                createActivity(
                                  "Pending -> Positive",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Positive",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Negative",
                              command: () => {
                                createActivity(
                                  "Pending -> Negative",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Negative",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Recommended",
                              command: () => {
                                createActivity(
                                  "Pending -> Recommended",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Recommended",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Not Recommended",
                              command: () => {
                                createActivity(
                                  "Pending -> Not Recommended",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Not Recommended",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Failed",
                              command: () => {
                                createActivity(
                                  "Pending -> Failed",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Failed",

                                  item?._id,
                                  index
                                );
                              },
                            },
                            {
                              label: "Could Not Verified",
                              command: () => {
                                createActivity(
                                  "Pending -> Could Not Verified",
                                  item?.userVerificationRequestId,
                                  item?._id
                                );
                                updateCheckStatus(
                                  "Could Not Verified",

                                  item?._id,
                                  index
                                );
                              },
                            },
                          ]}
                        />
                      )}
                      <div className="ml-[20px]">
                        {getSeverity(item?.bank?.status)}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {process.env.REACT_APP_IS_GVS_REPORT_ENABLED === "true" && (
                <form>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Account Number
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="accountNumber"
                        onChange={(e) => onInputChange(e, index, "numeric")}
                        value={item?.bank?.accountNumber || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Re-enter Account Number
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="reEnterAccount"
                        onChange={(e) => onInputChange(e, index, "numeric")}
                        value={item?.bank?.reEnterAccount || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Name of Account Holder
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="fullName"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.bank?.fullName || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Bank Name
                      </label>
                      <InputText
                        className="form-control"
                        type="int"
                        name="bankName"
                        maxLength={10}
                        onChange={(e) =>
                          onInputChange(e, index, "alphanumeric")
                        }
                        value={item?.bank?.bankName || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        Branch
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="branch"
                        onChange={(e) => onInputChange(e, index, "text")}
                        value={item?.bank?.branch || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="small mb-1" htmlFor="entityName">
                        IFSC Code
                      </label>
                      <InputText
                        className="form-control"
                        type="text"
                        name="ifsc"
                        onChange={(e) =>
                          onInputChange(e, index, "alphanumeric")
                        }
                        value={item?.bank?.ifsc || ""}
                        disabled={
                          flag === "true" ||
                          localStorageData?.role === "Auditor"
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <label className="small mb-1" htmlFor="clientType">
                      Additional Remarks
                    </label>
                    <InputTextarea
                      className="form-control"
                      type="text"
                      name="additionalRemarks"
                      onChange={(e) => onInputChange(e, index, "alphanumeric")}
                      value={item?.bank?.additionalRemarks || ""}
                      disabled={flag === "true"}
                      rows={5}
                      cols={30}
                    />
                  </div>
                  <div className="row my-2">
                    <div className="col-md-12 border-bottom">
                      <label className="small mb-1" htmlFor="clientType">
                        File Upload{" "}
                        <span className="opacity-70">
                          (Please Choose JPEG,PNG,PDF less than 5MB)
                        </span>
                      </label>
                      {item?.bank?.reportUrl?.length === 0 ||
                      editable === true ? (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*,application/pdf"
                          customUpload={true}
                          // maxFileSize={50000000}
                          uploadHandler={(e) => handleFileUpload(e, "file")}
                          itemTemplate={customItemTemplate}
                          disabled={localStorageData?.role === "BCAStaff"}
                        />
                      ) : (
                        ""
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.bank?.reportUrl || []).map(
                          (fileUrl, reportIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            const isPdf = fileExtension === "pdf";
                            return (
                              <li
                                key={reportIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      reportIndex,
                                      "file"
                                    )
                                  }
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      index,
                                      reportIndex,
                                      "img"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />

                                {fileMap[index]?.[reportIndex] && (
                                  <div className="text-center mt-2">
                                    {isImage && (
                                      <a
                                        href={fileMap[index][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${index}_${reportIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                    {isPdf && (
                                      <a
                                        href={fileMap[index][reportIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-center"
                                      >
                                        View PDF
                                      </a>
                                    )}
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>

                  {localStorageData?.role !== "user" && (
                    <div className="col-md-8">
                      <label className="small mb-1" htmlFor="remark">
                        Remark
                      </label>
                      <textarea
                        className="form-control h-[110px]"
                        name="remark"
                        onChange={(e) => {
                          // setRemarkForActivity(e.target.value);
                          onInputChange(e, index, "alphanumeric");
                        }}
                        value={item?.bank?.remark || ""}
                        disabled={flag === "false"}
                      />
                    </div>
                  )}

                  {localStorageData?.role !== "user" && (
                    <div className="col-md-12 my-2">
                      <label className="small mb-1" htmlFor="clientType">
                        Upload Proof{" "}
                        <span className="opacity-70">
                          (Please Choose PDF less than 10MB)
                        </span>
                      </label>
                      {item?.bank?.uploadProofUrl?.length === 0 && (
                        <FileUpload
                          name="fileUpload[]"
                          auto
                          multiple
                          accept="image/*"
                          customUpload={true}
                          maxFileSize={10000000}
                          uploadHandler={(e) =>
                            handleFileUpload(e, "uploadProof")
                          }
                          itemTemplate={customItemTemplate}
                          disabled={flag === "false"}
                        />
                      )}
                      <ul className="!pl-0 gap-2 mt-2 flex flex-row overflow-x-auto overflow-style-none md:rounded-lg">
                        {(item?.bank?.uploadProofUrl || []).map(
                          (fileUrl, proofIndex) => {
                            // Extract file name from the URL
                            const fileName = fileUrl.split("/").pop();
                            const fileExtension = fileName
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const isImage = ["jpeg", "png"].includes(
                              fileExtension
                            );

                            return (
                              <li
                                key={proofIndex}
                                className="flex flex-col gap-y-[15px] p-3 border-2 shadow-md rounded-[10px]"
                              >
                                <div
                                  role="presentation"
                                  onClick={(e) =>
                                    removeImg(
                                      e,
                                      item._id,
                                      item,
                                      proofIndex,
                                      "proof"
                                    )
                                  }
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red" }}
                                  ></i>
                                </div>
                                <Button
                                  label="Download"
                                  onClick={(e) =>
                                    getImg(
                                      e,
                                      fileName,
                                      index,
                                      proofIndex,
                                      "proof"
                                    )
                                  }
                                  className="w-[120px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                                />
                                <div className="text-center mt-2">
                                  {isImage &&
                                    proofImage[index]?.[proofIndex] && (
                                      <a
                                        href={proofImage[index][proofIndex]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={`file_${index}_${proofIndex}`}
                                      >
                                        View Image
                                      </a>
                                    )}
                                </div>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )}

                  <div className="flex max-sm:flex max-sm:flex-col justify-end gap-2 mt-[10px]">
                    {flag === "false" && item?.bank?.status === "Pending" ? (
                      item?.bank?.reportUrl?.length > 0 ? (
                        <Button
                          label="Edit"
                          type="button"
                          onClick={(e) => {
                            handleEdit(e);
                          }}
                          className="w-[84px] h-[40px] text-[14px] text-white !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                    <div className="flex gap-2">
                      <Button
                        label="Save"
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                          updateBankDetails(e, item._id, index);
                        }}
                        className="w-[84px] h-[40px] text-[14px] text-white font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                      />
                      <div>{getSeverity(item?.bank?.status)}</div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </Card>

          <div style={{ marginTop: "40px" }}>
            <Inefficiency
              InefficiencyId={item?.InefficiencyId}
              caseId={item?.userVerificationRequestId}
              checkId={item?._id}
              checkName="Bank"
            />
          </div>
          {localStorageData?.role === "user" && (
            <ConfirmDialog visible={isModalOpen} />
          )}
        </>
      ))}
    </>
  );
};

export default BankDetails;
