// src/utils/logout.js
import { toast } from "react-toastify";
import ApiService from "../services/APIServices";

const logout = async (navigate, toastMessage) => {
  try {
    const token = localStorage.getItem("token");
    if (token) {
      await ApiService.post("invalidate-token", { token });
    }
  } catch (error) {
    console.error("Failed to invalidate token", error);
  }

  // Clear local storage and navigate to login page
  localStorage.clear();
  {
    process.env.REACT_APP_FRONTEND_BASE_URL ===
    "https://vsmart.vleadergroup.com/"
      ? navigate("/signin")
      : navigate("/login");
  }

  // Show toaster message
  toast.warning(toastMessage);
  // Refresh the page
  window.location.reload();
};

export default logout;
