import React, { useState, useEffect } from "react";
import ApiService from "../../../services/APIServices";
import Icons from "../../../Helper/Icons.jsx";
import { toast } from "react-toastify";
import CreditDetails from "../../StaffMember/CreditDetails.jsx";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import NoData from "../../../Helper/NoDataFound.jsx";
import { ProgressSpinner } from "primereact/progressspinner";

export default function CreditCheck({
  userVerificationRequestInfo,
  info,
  taskDetail,
  flag,
}) {
  const [selectedStaffForCreditCheck, setSelectedStaffForCreditCheck] =
    useState(null);
  const [
    selectedStaffForInternalCreditCheck,
    setSelectedStaffForInternalCreditCheck,
  ] = useState(null);
  const [BCAStaffList, setBCAStaffList] = useState([]);
  const [BCAStaffListInternal, setBCAStaffListInternal] = useState([]);
  const [creditCheckInfo, setCreditCheckInfo] = useState([]);
  const [creditCheckId, setCreditCheckId] = useState(null);
  const [assignLoading, setAssignLoading] = useState(false);
  const [addNewLoading, setAddNewLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [missingCredits, setMissingCredits] = useState([]);
  const [activityDetails, setActivityDetails] = useState([]);
  const [showActivity, setShowActivity] = useState(false);
  const [showReWork, setShowReWork] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));

  const getBCAStaffByBCAId = async (id) => {
    await ApiService.get(`BCAStaff/List/${id}`)
      .then((log) => {
        // Filter the data
        const filteredData = log?.data.filter((staff) => {
          return (
            staff.type !== "Internal" &&
            (staff.assignedChecks.length === 0 ||
              staff.assignedChecks.includes("Credit"))
          );
        });
        console.log("staffListForCredit>>", filteredData);

        setBCAStaffList(filteredData); // Set the filtered data to the state
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBCAInternalStaffByBCAId = async (id) => {
    await ApiService.get(`BCAStaff/List/${id}`)
      .then((log) => {
        // Filter the data
        const filteredData = log?.data.filter((staff) => {
          return (
            staff.type === "Internal" &&
            (staff.assignedChecks.length === 0 ||
              staff.assignedChecks.includes("Credit"))
          );
        });
        console.log("staffListForCredit>>", filteredData);

        setBCAStaffListInternal(filteredData); // Set the filtered data to the state
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log(userVerificationRequestInfo?.checksOption, "CHECKS++");

    if (user?.role !== "company" && user?.role !== "user") {
      if (user?.role === "BCA") {
        getBCAStaffByBCAId(userVerificationRequestInfo?.BCAId);
        getBCAInternalStaffByBCAId(userVerificationRequestInfo?.BCAId);
      } else if (user?.role === "Vendor") {
        getBCAStaffByBCAId(userVerificationRequestInfo?.vendorId);
        getBCAInternalStaffByBCAId(userVerificationRequestInfo?.vendorId);
      } else if (user?.role === "Coordinator") {
        getBCAStaffByBCAId(user?.BCAId);
        getBCAInternalStaffByBCAId(user?.BCAId);
      } else if (user?.role === "FieldCoordinator") {
        getBCAStaffByBCAId(user?.BCAId);
        getBCAInternalStaffByBCAId(user?.BCAId);
      } else {
        getBCAStaffByBCAId(user?._id);
        getBCAInternalStaffByBCAId(user?.BCAId);
      }
    }
    getCreditCheckInfo(userVerificationRequestInfo?._id);
  }, [userVerificationRequestInfo?._id]);

  useEffect(() => {
    console.log(missingCredits, "missingCredits");
  }, [missingCredits.length > 0]);

  useEffect(() => {
    if (
      creditCheckInfo.length >= 0 &&
      userVerificationRequestInfo?.checksOption?.credit?.length > 0
    ) {
      const missingQualifications = [];

      userVerificationRequestInfo.checksOption.credit.forEach((option) => {
        const normalizedOption = option;

        const found = creditCheckInfo.some(
          (check) => check.credit.qualification === normalizedOption
        );

        if (!found) {
          missingQualifications.push(option);
        }
      });

      setMissingCredits(missingQualifications);

      if (missingQualifications.length > 0) {
        console.log("Missing Credit:", missingQualifications);
      }
    }
  }, [creditCheckInfo, userVerificationRequestInfo?.checksOption?.credit]);

  const getCreditCheckInfo = async (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "creditCheck/link";
    } else {
      url = "creditCheck";
    }
    setLoading(true);
    await ApiService.get(`${url}/${id}`)
      .then((res) => {
        console.log("RESSSSSSSSSSSSSSSSSSSSSSSS>>>>>>>", res);
        setCreditCheckInfo(res?.data?.docs);
        setCreditCheckId(res?.data?.docs[0]?._id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleCreateTaskAPI = async () => {
    setAssignLoading(true);
    try {
      let body = {
        userVerificationRequestId: userVerificationRequestInfo._id,
        BCAId: userVerificationRequestInfo?.BCAId,
        assignedStaffMemberId:
          selectedStaffForCreditCheck?._id ||
          selectedStaffForInternalCreditCheck?._id,
        status: "InProgress",
        checkListItem: [],
        checkListKey: "Credit",
      };

      if (userVerificationRequestInfo?.CreditTaskId === null) {
        // body.BCAId = assignEducationTask?.selectedStaff?.BCAId;
        // body.assignedStaffMemberId = assignEducationTask?.selectedStaff?._id;
        body.checkListItem = creditCheckInfo;
        await createTask(body);
      } else {
        // body.BCAId = assignEducationTask.selectedStaff.BCAId;
        // body.assignedStaffMemberId = assignEducationTask.selectedStaff._id;
        body.checkListItem = creditCheckInfo;
        let path = `task/${userVerificationRequestInfo?.CreditTaskId}`;
        console.log("PATH>>>>>>>>>>>>>>>", path);
        await updateTask(path, {
          assignedStaffMemberId:
            selectedStaffForCreditCheck?._id ||
            selectedStaffForInternalCreditCheck?._id,
        });
      }

      if (body.BCAId === "" || body.BCAId === undefined) {
        // toast.error("Select the BCA");
      }
    } catch (error) {
      toast.error("Error in Assign task");
      console.error("Error in API:", error);
      setAssignLoading(false);
    }
  };
  const updateBcaConsentReq = async (type) => {
    let body = {
      status: type,
    };
    ApiService.post(
      `userVerificationRequest/${userVerificationRequestInfo?._id}`,
      body
    )
      .then(() => {
        info();
      })
      .catch((err) => {
        console.log(err);
        // setShowConsentModal(false);
      });
  };
  const updateCaseIsAssigned = async () => {
    let currentDate = new Date();
    let body = {
      isAssigned: {
        status: true,
        dateAndTime: currentDate,
      },
    };
    ApiService.post(
      `userVerificationRequest/${userVerificationRequestInfo?._id}`,
      body
    )
      .then(() => {
        // toast.success("Case Key Updated Successfully");
        info();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const createTask = async (body) => {
    const response = await ApiService.post("task", body);
    if (response) {
      toast.success("Task created successfully");
      getCreditCheckInfo(userVerificationRequestInfo?._id);
      info();
      setAssignLoading(false);
      updateBcaConsentReq("InProgress");
      updateCaseIsAssigned();
    }
  };
  const updateTask = async (path, body) => {
    const response = await ApiService.post(path, body);
    if (response) {
      toast.success("Task updated successfully");
      getCreditCheckInfo(userVerificationRequestInfo?._id);
      info();

      updateBcaConsentReq("InProgress");
      updateCaseIsAssigned();
      setAssignLoading(false);
    }
  };

  const createCreditCheck = async () => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "creditCheck/link";
    } else {
      url = "creditCheck";
    }
    setAddNewLoading(true);

    const body = {
      userVerificationRequestId: userVerificationRequestInfo?._id,
      credit: {
        type: "",
        panNumber: "",
        timeOfVerification: "",
        loan: "",
        updatedAddressInCIBIL: "",
        name: "",
        city: "",
        dateOfReceipt: "",
        employeeCode: "",
        fatherName: "",
        address: "",
        latitude: "",
        longitude: "",
        panStatus: "",
        individual: "",
        dateOfVerification: "",
        recordSummery: "",
        cibilScore: "",
        creditScore: "",
        additionalRemark: "",
        reWorkRemark: "",
        reportUrl: [],
        status: "Pending",
        remark: "",
        uploadProofUrl: [],
      },

      location: {
        latitute: "",
        longitute: "",
      },
    };

    await ApiService.post(`${url}`, body)
      .then(() => {
        toast.success("Credit Added successfully");
        setAddNewLoading(false);
        getCreditCheckInfo(userVerificationRequestInfo?._id);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
        setAddNewLoading(false);
      });
  };
  useEffect(() => {}, [creditCheckId]);

  const getActivityDetails = (id) => {
    ApiService.get(`caseActivity/byCaseId/${id}`)

      .then((res) => {
        // Sort the data based on createdAt field

        const sortedData = res?.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        const filteredData = sortedData.filter(
          (item) => item.checkName === "Credit"
        );

        console.log("Filtered Activity Details:", sortedData);

        setActivityDetails(filteredData);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const handlePrefillData = async (id) => {
    const response = await ApiService.get(`task/${id}`);

    if (BCAStaffList.length > 0) {
      const selectedStaff = BCAStaffList.find(
        (item) => item._id === response?.data.assignedStaffMemberId
      );

      return selectedStaff ? selectedStaff : null;
    }
  };

  const handlePrefillDataForInternal = async (id) => {
    const response = await ApiService.get(`task/${id}`);

    if (BCAStaffListInternal.length > 0) {
      const selectedStaff = BCAStaffListInternal.find(
        (item) => item._id === response?.data.assignedStaffMemberId
      );

      console.log("selectedStaffOnPrefill>>", selectedStaff);

      return selectedStaff ? selectedStaff : null;
    }
  };

  const prefillCreditStaff = async () => {
    try {
      const selectedstaff = await handlePrefillData(
        userVerificationRequestInfo?.CreditTaskId
      );
      setSelectedStaffForCreditCheck(selectedstaff);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const prefillInternalCreditStaff = async () => {
    try {
      const selectedstaff = await handlePrefillDataForInternal(
        userVerificationRequestInfo?.CreditTaskId
      );
      console.log("selectedstaffInternal>>", selectedstaff);

      setSelectedStaffForInternalCreditCheck(selectedstaff);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchStaffForCredit = async () => {
      if (userVerificationRequestInfo?.CreditTaskId !== null) {
        await prefillCreditStaff(userVerificationRequestInfo?.CreditTaskId);
      }
    };

    fetchStaffForCredit();
  }, [userVerificationRequestInfo !== "" && BCAStaffList.length > 0]);

  useEffect(() => {
    const fetchStaffForCredit = async () => {
      if (userVerificationRequestInfo?.CreditTaskId !== null) {
        await prefillInternalCreditStaff(
          userVerificationRequestInfo?.CreditTaskId
        );
      }
    };

    fetchStaffForCredit();
  }, [userVerificationRequestInfo !== "" && BCAStaffListInternal.length > 0]);

  return (
    <>
      <div>
        <div className="flex flex-col md:flex-row justify-between mb-[15px]">
          <div className="flex items-center flex-row gap-[10px]">
            <div className="mb-[9px]">
              <Icons name="reference-documents" color="grey" />
            </div>
            <h5>Credit Check</h5>
          </div>

          <div className="flex max-sm:flex-col gap-2">
            {creditCheckInfo.length > 0 &&
            (user?.role === "BCA" ||
              user?.role === "Vendor" ||
              user?.role === "FieldCoordinator" ||
              user?.role === "Coordinator") ? (
                <>
                  <Dropdown
                    inputId="dd-city"
                    value={selectedStaffForInternalCreditCheck}
                    onChange={(e) => {
                      setSelectedStaffForInternalCreditCheck(e.value);
                    }}
                    options={BCAStaffListInternal}
                    optionLabel="firstName"
                    className="w-[200px] h-[45px] max-sm:w-full"
                    placeholder="Assign Internal"
                    style={{ backgroundColor: "#F8F8F8" }}
                    disabled={creditCheckInfo.length === 0}
                  />
                  <Dropdown
                    inputId="dd-city"
                    value={selectedStaffForCreditCheck}
                    onChange={(e) => {
                      setSelectedStaffForCreditCheck(e.value);
                    }}
                    options={BCAStaffList}
                    optionLabel="firstName"
                    className="w-[200px] h-[45px] max-sm:w-full"
                    placeholder="Assign External"
                    style={{ backgroundColor: "#F8F8F8" }}
                    disabled={creditCheckInfo.length === 0}
                  />
                </>
              ) : (
                <></>
              )}
            <div className="flex gap-2 max-sm:flex max-sm:justify-evenly">
              {user?.role === "Auditor" ||
              user?.role === "BCAStaff" ||
              user?.role === "Coordinator" ? (
                  <Button
                    label="Re-Work"
                    type="button"
                    onClick={() => {
                      setShowReWork(!showReWork);
                    }}
                    icon={showReWork ? "pi pi-angle-down" : "pi pi-angle-right"}
                    iconPos="right"
                    className="w-[140px] h-[45px] text-[14px] text-white text-center !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                  />
                ) : (
                  <></>
                )}
              <Button
                label="Activity"
                type="button"
                onClick={() => {
                  getActivityDetails(userVerificationRequestInfo?._id);
                  setShowActivity(!showActivity);
                }}
                icon={showActivity ? "pi pi-angle-down" : "pi pi-angle-right"}
                iconPos="right"
                className="w-[140px] h-[45px] text-[14px] text-white text-center !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              />

              {creditCheckInfo.length > 0 &&
              (user?.role === "BCA" ||
                user?.role === "Vendor" ||
                user?.role === "FieldCoordinator" ||
                user?.role === "Coordinator") ? (
                  <Button
                    label="Assign"
                    type="button"
                    loading={assignLoading}
                    onClick={() => handleCreateTaskAPI()}
                    className="w-[90px] h-[45px] !text-[14px] text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                  />
                ) : (
                  <></>
                )}

              {flag === "false" && (
                <Button
                  label="Add New"
                  type="button"
                  loading={addNewLoading}
                  onClick={() => createCreditCheck()}
                  className="w-[130px] h-[45px] !text-[14px]  text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                />
              )}
            </div>
          </div>
        </div>
        {!loading && user?.role !== "BCAStaff" && (
          <div className="flex flex-row gap-[10px]">
            {missingCredits.length > 0 &&
              missingCredits.map((option, index) => (
                <Button
                  key={index}
                  label={option}
                  onClick={() => createCreditCheck(option)}
                  type="button"
                  className="w-[150px] h-[45px] !text-[14px] text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60 mb-2"
                />
              ))}
          </div>
        )}
      </div>

      {loading ? (
        <>
          <div className="flex justify-center items-center h-screen w-full">
            <ProgressSpinner
              style={{ width: "60px", height: "60px" }}
              strokeWidth="4"
              animationDuration=".7s"
            />
          </div>
        </>
      ) : (
        <>
          {creditCheckInfo.length === 0 ? (
            <>
              <div className="flex items-center">
                <NoData info="Ooops, we could not find any education check details" />
              </div>
            </>
          ) : (
            <>
              {creditCheckInfo.length > 0 &&
                creditCheckInfo !== null &&
                creditCheckId !== undefined &&
                creditCheckId !== null &&
                creditCheckInfo !== undefined && (
                <>
                  <CreditDetails
                    userVerificationDetail={userVerificationRequestInfo?._id}
                    userVerificationRequestInfo={
                      userVerificationRequestInfo?.CreditTaskId
                    }
                    creditCheckId={creditCheckId}
                    creditCheckInfo={creditCheckInfo}
                    getCreditCheckInfo={getCreditCheckInfo}
                    taskDetail={taskDetail}
                    activityDetails={activityDetails}
                    showActivity={showActivity}
                    showReWork={showReWork}
                    getActivityDetails={getActivityDetails}
                    flag={flag}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
