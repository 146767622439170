import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import ApiService from "../../../services/APIServices";
import Icons from "../../../Helper/Icons.jsx";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import NoData from "../../../Helper/NoDataFound.jsx";
import { ProgressSpinner } from "primereact/progressspinner";
import BankDetails from "../../StaffMember/BankDetails.jsx";

export default function BankCheck({
  userVerificationRequestInfo,
  taskDetail,
  info,
  flag,
}) {
  const [selectedStaffForRefrenceCheck, setSelectedStaffForRefrenceCheck] =
    useState(null);
  const [
    selectedStaffForInternalRefrenceCheck,
    setSelectedStaffForInternalRefrenceCheck,
  ] = useState(null);
  const [BCAStaffList, setBCAStaffList] = useState([]);
  const [BCAStaffListInternal, setBCAStaffListInternal] = useState([]);
  const [bankCheckInfo, setBankCheckInfo] = useState([]);
  const [bankCheckId, setBankCheckId] = useState(null);
  const [assignLoading, setAssignLoading] = useState(false);
  const [addNewLoading, setAddNewLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [missingBank, setMissingBank] = useState([]);
  const [activityDetails, setActivityDetails] = useState([]);
  const [showActivity, setShowActivity] = useState(false);
  const [showReWork, setShowReWork] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));

  const getBCAStaffByBCAId = async (id) => {
    await ApiService.get(`BCAStaff/List/${id}`)
      .then((log) => {
        // Filter the data
        const filteredData = log?.data.filter((staff) => {
          return (
            staff.type !== "Internal" &&
            (staff.assignedChecks.length === 0 ||
              staff.assignedChecks.includes("Bank"))
          );
        });
        console.log("staffListForBank>>", filteredData);

        setBCAStaffList(filteredData); // Set the filtered data to the state
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getBCAInternalStaffByBCAId = async (id) => {
    await ApiService.get(`BCAStaff/List/${id}`)
      .then((log) => {
        // Filter the data
        const filteredData = log?.data.filter((staff) => {
          return (
            staff.type === "Internal" &&
            (staff.assignedChecks.length === 0 ||
              staff.assignedChecks.includes("Bank"))
          );
        });
        console.log("staffListForBank>>", filteredData);

        setBCAStaffListInternal(filteredData); // Set the filtered data to the state
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user?.role !== "company" && user?.role !== "user") {
      if (user?.role === "BCA") {
        getBCAStaffByBCAId(userVerificationRequestInfo?.BCAId);
        getBCAInternalStaffByBCAId(userVerificationRequestInfo?.BCAId);
      } else if (user?.role === "Vendor") {
        getBCAStaffByBCAId(userVerificationRequestInfo?.vendorId);
        getBCAInternalStaffByBCAId(userVerificationRequestInfo?.vendorId);
      } else if (user?.role === "Coordinator") {
        getBCAStaffByBCAId(user?.BCAId);
        getBCAInternalStaffByBCAId(user?.BCAId);
      } else if (user?.role === "FieldCoordinator") {
        getBCAStaffByBCAId(user?.BCAId);
        getBCAInternalStaffByBCAId(user?.BCAId);
      } else {
        getBCAStaffByBCAId(user?._id);
        getBCAInternalStaffByBCAId(user?.BCAId);
      }
    }
    getBankCheckInfo(userVerificationRequestInfo?._id);
  }, [userVerificationRequestInfo?._id]);

  useEffect(() => {
    console.log(missingBank, "missingBank");
  }, [missingBank.length > 0]);

  useEffect(() => {
    if (
      bankCheckInfo.length >= 0 &&
      userVerificationRequestInfo?.checksOption?.bank?.length > 0
    ) {
      const missingQualifications = [];

      userVerificationRequestInfo.checksOption.bank.forEach((option) => {
        const normalizedOption = option;

        const found = bankCheckInfo.some(
          (check) => check.bank.type === normalizedOption
        );

        if (!found) {
          missingQualifications.push(option);
        }
      });

      setMissingBank(missingQualifications);

      if (missingQualifications.length > 0) {
        console.log("Missing bank:", missingQualifications);
      }
    }
  }, [bankCheckInfo, userVerificationRequestInfo?.checksOption?.bank]);
  const getBankCheckInfo = async (id) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "bankCheck/link";
    } else {
      url = "bankCheck";
    }
    setLoading(true);
    await ApiService.get(`${url}/${id}`)
      .then((res) => {
        setBankCheckInfo(res?.data?.docs);
        setBankCheckId(res?.data?.docs[0]?._id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const createBankCheck = async (option) => {
    let url;
    if (process.env.REACT_APP_PUBLIC_USER_LINK === "true") {
      url = "bankCheck/link";
    } else {
      url = "bankCheck";
    }
    setAddNewLoading(true);
    const body = {
      userVerificationRequestId: userVerificationRequestInfo?._id,
      bank: {
        type: option === "" ? "" : option,
        accountNumber: "",
        reEnterAccount: "",
        fullName: "",
        bankName: "",
        branch: "",
        ifsc: "",
        refereeName: "",
        companyAddress: "",
        relationWithReferee: "",
        contactNumber: "",
        organization: "",
        applicantName: "",
        employerName: "",
        lastPosition: "",
        responsibilities: "",
        yearOfAssociation: "",
        subjectKnowledge: "",
        softSkills: "",
        behavior: "",
        assessment: "",
        email: "",
        companyNameAndDesignation: "",
        candidateYear: "",
        periodOfReporting: "",
        designation: "",
        attitudeTowards: "",
        duties: "",
        effective: "",
        abilityToWork: "",
        communicationSkill: "",
        interpersonalSkills: "",
        professionalSkills: "",
        timeManagementSkill: "",
        wriitenSkill: "",
        oralSkill: "",
        technicalSkill: "",
        leadershipSkill: "",
        professionalStrength: "",
        professionalWeakness: "",
        professionalRelation: "",
        personalIssues: "",
        irdIssue: "",
        attendance: "",
        honesty: "",
        integrity: "",
        eligibilityRehire: "",
        performanceRating: "",
        additionalComments: "",
        additionalRemarks: "",
        city: "",
        state: "",
        country: "",
        pinCode: "",
        reWorkRemark: "",
        status: "Pending",
        remark: "",
        leavingJob: "",
        complaint: "",
        recommendMNC: "",
        misrepresenting: "",
        teamPlayer: "",
        uploadProofUrl: [],
        reportUrl: [],
      },
      verificationDetails: {
        remarkDate: "",
      },
      location: {
        latitute: "",
        longitute: "",
      },
    };

    await ApiService.post(`${url}`, body)
      .then(() => {
        toast.success("Bank Added successfully");
        setAddNewLoading(false);
        getBankCheckInfo(userVerificationRequestInfo?._id);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
        setAddNewLoading(false);
      });
  };
  useEffect(() => {}, [bankCheckId]);

  const handlePrefillData = async (id) => {
    const response = await ApiService.get(`task/${id}`);

    if (BCAStaffList.length > 0) {
      const selectedStaff = BCAStaffList.find(
        (item) => item._id === response?.data.assignedStaffMemberId
      );
      return selectedStaff ? selectedStaff : null;
    }
  };

  const handlePrefillDataForInternal = async (id) => {
    const response = await ApiService.get(`task/${id}`);

    if (BCAStaffListInternal.length > 0) {
      const selectedStaff = BCAStaffListInternal.find(
        (item) => item._id === response?.data.assignedStaffMemberId
      );

      console.log("selectedStaffOnPrefill>>", selectedStaff);

      return selectedStaff ? selectedStaff : null;
    }
  };

  const prefillAddressStaff = async () => {
    try {
      const selectedstaff = await handlePrefillData(
        userVerificationRequestInfo?.BankTaskId
      );

      setSelectedStaffForRefrenceCheck(selectedstaff);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const prefillInternalExperienceStaff = async () => {
    try {
      const selectedstaff = await handlePrefillDataForInternal(
        userVerificationRequestInfo?.BankTaskId
      );
      console.log("selectedstaffInternal>>", selectedstaff);

      setSelectedStaffForInternalRefrenceCheck(selectedstaff);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchStaffForAddress = async () => {
      if (userVerificationRequestInfo?.BankTaskId !== null) {
        await prefillAddressStaff(userVerificationRequestInfo?.BankTaskId);
      }
    };

    fetchStaffForAddress();
  }, [userVerificationRequestInfo !== "" && BCAStaffList.length > 0]);

  useEffect(() => {
    const fetchStaffForEducation = async () => {
      if (userVerificationRequestInfo?.BankTaskId !== null) {
        await prefillInternalExperienceStaff(
          userVerificationRequestInfo?.BankTaskId
        );
      }
    };

    fetchStaffForEducation();
  }, [userVerificationRequestInfo !== "" && BCAStaffListInternal.length > 0]);

  const handleCreateTaskAPI = async () => {
    setAssignLoading(true);
    try {
      let body = {
        userVerificationRequestId: userVerificationRequestInfo._id,
        BCAId: userVerificationRequestInfo?.BCAId,
        assignedStaffMemberId:
          selectedStaffForRefrenceCheck?._id ||
          selectedStaffForInternalRefrenceCheck?._id,
        status: "InProgress",
        checkListItem: [],
        checkListKey: "Bank",
      };

      if (userVerificationRequestInfo?.BankTaskId === null) {
        body.checkListItem = bankCheckInfo;
        await createTask(body);
      } else {
        body.checkListItem = bankCheckInfo;
        let path = `task/${userVerificationRequestInfo?.BankTaskId}`;
        await updateTask(path, {
          assignedStaffMemberId:
            selectedStaffForRefrenceCheck?._id ||
            selectedStaffForInternalRefrenceCheck?._id,
        });
      }

      if (body.BCAId === "" || body.BCAId === undefined) {
        // toast.error("Select the BCA");
      }
    } catch (error) {
      toast.error("Error in Assign task");
      console.error("Error in API:", error);
      setAssignLoading(false);
    }
  };
  const updateBcaConsentReq = async (type) => {
    let body = {
      status: type,
    };
    ApiService.post(
      `userVerificationRequest/${userVerificationRequestInfo?._id}`,
      body
    )
      .then(() => {
        info();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCaseIsAssigned = async () => {
    let currentDate = new Date();
    let body = {
      isAssigned: {
        status: true,
        dateAndTime: currentDate,
      },
    };
    ApiService.post(
      `userVerificationRequest/${userVerificationRequestInfo?._id}`,
      body
    )
      .then(() => {
        // toast.success("Case Key Updated Successfully");
        info();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createTask = async (body) => {
    const response = await ApiService.post("task", body);
    if (response) {
      toast.success("Task created successfully");
      getBankCheckInfo(userVerificationRequestInfo?._id);
      setAssignLoading(false);
      info();
      updateBcaConsentReq("InProgress");
      updateCaseIsAssigned();
    }
  };
  const updateTask = async (path, body) => {
    const response = await ApiService.post(path, body);
    if (response) {
      toast.success("Task updated successfully");
      setAssignLoading(false);
      getBankCheckInfo(userVerificationRequestInfo?._id);
      info();
      updateBcaConsentReq("InProgress");
      updateCaseIsAssigned();
    }
  };
  const getActivityDetails = (id) => {
    ApiService.get(`caseActivity/byCaseId/${id}`)

      .then((res) => {
        // Sort the data based on createdAt field

        const sortedData = res?.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        const filteredData = sortedData.filter(
          (item) => item.checkName === "Bank"
        );

        console.log("Filtered Activity Details:", sortedData);

        setActivityDetails(filteredData);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div>
        <div className="flex flex-col md:flex-row justify-between mb-[15px]">
          <div className="flex items-center flex-row gap-[10px]">
            <div className="mb-[9px]">
              <Icons name="bank-documents" color="grey" />
            </div>
            <h5>Bank Check</h5>
          </div>

          <div className="flex max-sm:flex-col gap-2">
            {bankCheckInfo.length > 0 &&
            (user?.role === "BCA" ||
              user?.role === "Vendor" ||
              user?.role === "FieldCoordinator" ||
              user?.role === "Coordinator") ? (
              <>
                <Dropdown
                  inputId="dd-city"
                  value={selectedStaffForInternalRefrenceCheck}
                  onChange={(e) => {
                    setSelectedStaffForInternalRefrenceCheck(e.value);
                  }}
                  options={BCAStaffListInternal}
                  optionLabel="firstName"
                  className="w-[200px] h-[45px] max-sm:w-full"
                  placeholder="Assign Internal"
                  style={{ backgroundColor: "#F8F8F8" }}
                  disabled={bankCheckInfo.length === 0}
                />
                <Dropdown
                  inputId="dd-city"
                  value={selectedStaffForRefrenceCheck}
                  onChange={(e) => {
                    setSelectedStaffForRefrenceCheck(e.value);
                  }}
                  options={BCAStaffList}
                  optionLabel="firstName"
                  className="w-[200px] h-[45px] max-sm:w-full"
                  placeholder="Assign External"
                  style={{ backgroundColor: "#F8F8F8" }}
                  disabled={bankCheckInfo.length === 0}
                />
              </>
            ) : (
              ""
            )}
            <div className="flex gap-2 max-sm:flex max-sm:justify-evenly">
              {user?.role === "Auditor" ||
              user?.role === "BCAStaff" ||
              user?.role === "Coordinator" ? (
                <Button
                  label="Re-Work"
                  type="button"
                  onClick={() => {
                    setShowReWork(!showReWork);
                  }}
                  icon={showReWork ? "pi pi-angle-down" : "pi pi-angle-right"}
                  iconPos="right"
                  className="w-[140px] h-[45px] text-[14px] text-white text-center !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                />
              ) : (
                <></>
              )}
              <Button
                label="Activity"
                type="button"
                onClick={() => {
                  getActivityDetails(userVerificationRequestInfo?._id);
                  setShowActivity(!showActivity);
                }}
                icon={showActivity ? "pi pi-angle-down" : "pi pi-angle-right"}
                iconPos="right"
                className="w-[140px] h-[45px] text-[14px] text-white text-center !font-medium !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
              />
              {bankCheckInfo.length > 0 &&
              (user?.role === "BCA" ||
                user?.role === "Vendor" ||
                user?.role === "FieldCoordinator" ||
                user?.role === "Coordinator") ? (
                <Button
                  label="Assign"
                  type="button"
                  loading={assignLoading}
                  onClick={() => handleCreateTaskAPI()}
                  className="w-[90px] h-[45px] !text-[14px] text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                />
              ) : (
                ""
              )}
              {flag === "false" && (
                <Button
                  label="Add New"
                  type="button"
                  loading={addNewLoading}
                  onClick={() => createBankCheck()}
                  className="w-[130px] h-[45px] !text-[14px]  text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60"
                />
              )}
            </div>
          </div>
        </div>
        {!loading && user?.role !== "BCAStaff" && (
          <div className="flex flex-row gap-[10px]">
            {missingBank.length > 0 &&
              missingBank.map((option, index) => (
                <Button
                  key={index}
                  label={option}
                  onClick={() => createBankCheck(option)}
                  type="button"
                  className="w-[150px] h-[45px] !text-[14px] text-white !bg-gradient-to-r from-sky-800 to-teal-400 rounded-[10px] shadow disabled:opacity-60 mb-2"
                />
              ))}
          </div>
        )}
      </div>
      {loading ? (
        <>
          <div className="flex justify-center items-center h-screen w-full">
            <ProgressSpinner
              style={{ width: "60px", height: "60px" }}
              strokeWidth="4"
              animationDuration=".7s"
            />
          </div>
        </>
      ) : (
        <>
          {bankCheckInfo.length === 0 ? (
            <>
              <div className="flex items-center">
                <NoData info="Ooops, we could not find any bank check detail" />
              </div>
            </>
          ) : (
            <>
              {bankCheckInfo.length > 0 &&
                bankCheckInfo !== null &&
                bankCheckId !== undefined &&
                bankCheckId !== null &&
                bankCheckInfo !== undefined && (
                  <>
                    <BankDetails
                      userVerificationDetail={userVerificationRequestInfo?._id}
                      userVerificationRequestInfo={
                        userVerificationRequestInfo?.BankTaskId
                      }
                      bankCheckId={bankCheckId}
                      bankCheckInfo={bankCheckInfo}
                      getBankCheckInfo={getBankCheckInfo}
                      taskDetail={taskDetail}
                      activityDetails={activityDetails}
                      showActivity={showActivity}
                      showReWork={showReWork}
                      getActivityDetails={getActivityDetails}
                      flag={flag}
                    />
                  </>
                )}
            </>
          )}
        </>
      )}
    </>
  );
}
