import React from "react";

export const envChecks = {
  Education: process.env.REACT_APP_IS_EDUCATION_CHECKLIST_ENABLED === "true",
  Identity: process.env.REACT_APP_IS_IDENTITY_CHECKLIST_ENABLED === "true",
  Certificate:
    process.env.REACT_APP_IS_CERTIFICATE_CHECKLIST_ENABLED === "true",
  Drug: process.env.REACT_APP_IS_DRUG_CHECKLIST_ENABLED === "true",
  Credit: process.env.REACT_APP_IS_CREDIT_CHECKLIST_ENABLED === "true",
  Court: process.env.REACT_APP_IS_COURT_CHECKLIST_ENABLED === "true",
  Police: process.env.REACT_APP_IS_POLICE_CHECKLIST_ENABLED === "true",
  Experience: process.env.REACT_APP_IS_EXPERIENCE_CHECKLIST_ENABLED === "true",
  Address: process.env.REACT_APP_IS_ADDRESS_CHECKLIST_ENABLED === "true",
  VideoKyc: process.env.REACT_APP_IS_VIDEOKYC_CHECKLIST_ENABLED === "true",
  GlobalDatabase:
    process.env.REACT_APP_IS_GLOBALDATABASE_CHECKLIST_ENABLED === "true",
  SexOffender:
    process.env.REACT_APP_IS_SEXOFFENDER_CHECKLIST_ENABLED === "true",
  Reference: process.env.REACT_APP_IS_REFERENCE_CHECKLIST_ENABLED === "true",
  EDD: process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED === "true",
  DISCRETECALLS: process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED === "true",
  KEIPROCESS: process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED === "true",
  VOLOHEALTHCARE:
    process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED === "true",
  REEDD_SURPRISE:
    process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED === "true",
  REEDD_APPOINTMENT:
    process.env.REACT_APP_IS_DISCRETE_CHECKLIST_ENABLED === "true",
  PROFESSIONAL_LICENSE:
    process.env.REACT_APP_IS_PROFESSIONAL_LICENSE_CHECKLIST_ENABLED === "true",
  REPUTATIONAL_DATABASE:
    process.env.REACT_APP_IS_REPUTATIONAL_DATABASE_CHECKLIST_ENABLED === "true",
  UAN: process.env.REACT_APP_IS_UAN_CHECKLIST_ENABLED === "true",
  SOCIAL_MEDIA:
    process.env.REACT_APP_IS_SOCIAL_MEDIA_CHECKLIST_ENABLED === "true",
  CRIMINAL: process.env.REACT_APP_IS_CRIMINAL_CHECKLIST_ENABLED === "true",
  Bank: process.env.REACT_APP_IS_BANK_CHECKLIST_ENABLED === "true",
};

export const indusIndBankChecks = [
  { name: "EDD", value: "isEDD" },
  { name: "REEDD (Appointment)", value: "isREEDDAPPOINTMENT" },
  { name: "REEDD (Surprise)", value: "isREEDDSURPRISE" },
];

export const VerificationStatusParameters = [
  { name: "All", value: "" },
  { name: "Pending", value: "Pending" },
  { name: "Completed", value: "Approved" },
  { name: "InProgress", value: "InProgress" },
];

export const paymentStatusFilter = [
  { name: "All", value: "" },
  { name: "Pending", value: "issued" },
  { name: "Paid", value: "paid" },
];

export const cooordinatorList = [
  { name: "Jyoti", value: "Jyoti" },
  { name: "Chetna", value: "Chetna" },
  { name: "Vijay", value: "Vijay" },
];

export const priorityFilters = [
  { name: " Select Priority", value: "" },
  { name: " Critical", value: "Critical" },
  { name: " High", value: "High" },
  { name: " Low", value: "Low" },
  { name: " Lowest", value: "Lowest" },
];

export const dateFilterStatus = [
  { name: "Request Date", value: "requestedDate" },
  { name: "Submission Date", value: "finalReportDate" },
];

export const getSeverity = (status) => {
  switch (status) {
    case "Major Discrepancy":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-red-300 bg-opacity-10 rounded-[5px] border border-red-300">
            <div className="text-red-500 text-xs font-medium m-auto whitespace-nowrap">
              Major Discrepancy
            </div>
          </div>
        </>
      );
    case "Minor Discrepancy":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-amber-300 bg-opacity-10 rounded-[5px] border border-amber-300">
            <div className="text-amber-500 text-xs font-medium m-auto">
              Minor Discrepancy
            </div>
          </div>
        </>
      );
    case "Verified Clear":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-emerald-500 bg-opacity-10 rounded-[5px] border border-blue-500">
            <div className="text-emerald-500 text-xs font-medium m-auto">
              Verified Clear
            </div>
          </div>
        </>
      );
    case "Insufficiency":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-blue-300 bg-opacity-10 rounded-[5px] border border-blue-300">
            <div className="text-blue-500 text-xs font-medium m-auto">
              Insufficiency
            </div>
          </div>
        </>
      );
    case "MovedToEntry":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-blue-200 bg-opacity-10 rounded-[5px] border border-blue-300">
            <div className="text-blue-700 text-xs font-medium m-auto">
              MovedToEntry
            </div>
          </div>
        </>
      );
    case "Details-Filled":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-blue-200 bg-opacity-10 rounded-[5px] border border-blue-300">
            <div className="text-blue-700 text-xs font-medium m-auto">
              Details-Filled
            </div>
          </div>
        </>
      );
    case "QC-Open":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-blue-200 bg-opacity-10 rounded-[5px] border border-blue-300">
            <div className="text-blue-700 text-xs font-medium m-auto">
              QC-Open
            </div>
          </div>
        </>
      );
    case "QCApproved":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-green-200 bg-opacity-10 rounded-[5px] border border-green-300">
            <div className="text-green-500 text-xs font-medium m-auto">
              QCApproved
            </div>
          </div>
        </>
      );
    case "QCRejected":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-red-200 bg-opacity-10 rounded-[5px] border border-red-300">
            <div className="text-red-700 text-xs font-medium m-auto">
              QCRejected
            </div>
          </div>
        </>
      );
    case "Unable to Verify":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-amber-300 bg-opacity-10 rounded-[5px] border border-amber-300">
            <div className="text-amber-500 text-xs font-medium m-auto">
              Unable to Verify
            </div>
          </div>
        </>
      );
    case "Stop Check":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-yellow-300 rounded-[5px] border border-gray-300">
            <div className="text-black-500 text-xs font-medium m-auto">
              Stop Check
            </div>
          </div>
        </>
      );
    case "Rejected":
      return (
        <>
          <div className="flex flex-row p-[6px] w-[104px] h-[30px] bg-red-300 bg-opacity-10 rounded-[5px] border border-red-300">
            <div className="text-red-500 text-xs font-medium m-auto">
              Rejected
            </div>
          </div>
        </>
      );
    case "Approved":
      return (
        <>
          <div className="flex flex-row p-[6px] w-[104px] h-[30px] bg-emerald-500 bg-opacity-10 rounded-[5px] border border-blue-500">
            <div className="text-emerald-500 text-xs font-medium m-auto">
              Approved
            </div>
          </div>
        </>
      );
    case "Completed":
      return (
        <>
          <div className="flex flex-row p-[6px] w-[104px] h-[30px] bg-emerald-500 bg-opacity-10 rounded-[5px] border border-blue-500">
            <div className="text-emerald-500 text-xs font-medium m-auto">
              Completed
            </div>
          </div>
        </>
      );
    case "InProgress":
      return (
        <>
          <>
            <div className="flex flex-row p-[6px] w-[104px] h-[30px] bg-blue-500 bg-opacity-10 rounded-[5px] border border-emerald-500">
              <div className="text-blue-500 text-xs font-medium m-auto">
                InProgress
              </div>
            </div>
          </>
        </>
      );
    case "Pending":
      return (
        <>
          <div className="flex flex-row p-[6px] w-[104px] h-[30px] bg-amber-300 bg-opacity-10 rounded-[5px] border border-amber-300">
            <div className="text-amber-300 text-xs font-medium m-auto">
              Pending
            </div>
          </div>
        </>
      );
    case "BcaApproval":
      return (
        <>
          <div className="flex flex-row p-[6px] w-[104px] h-[30px] bg-emerald-500 bg-opacity-10 rounded-[5px] border border-emerald-500">
            <div className="text-emerald-500 text-xs font-medium m-auto">
              BcaApproval
            </div>
          </div>
        </>
      );
    case "BcaRejection":
      return (
        <>
          <div className="flex flex-row p-[6px] w-[104px] h-[30px] bg-red-300 bg-opacity-10 rounded-[5px] border border-red-300">
            <div className="text-red-600 text-xs font-medium m-auto">
              BcaRejection
            </div>
          </div>
        </>
      );
    case "Positive":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-green-300 bg-opacity-10 rounded-[5px] border border-green-300">
            <div className="text-green-500 text-xs font-medium m-auto whitespace-nowrap">
              Positive
            </div>
          </div>
        </>
      );
    case "Negative":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-red-300 bg-opacity-10 rounded-[5px] border border-red-300">
            <div className="text-red-500 text-xs font-medium m-auto whitespace-nowrap">
              Negative
            </div>
          </div>
        </>
      );
    case "Recommended":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-yellow-300 bg-opacity-10 rounded-[5px] border border-yellow-300">
            <div className="text-yellow-500 text-xs font-medium m-auto whitespace-nowrap">
              Recommended
            </div>
          </div>
        </>
      );
    case "Not Recommended":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-blue-300 bg-opacity-10 rounded-[5px] border border-blue-300">
            <div className="text-blue-500 text-xs font-medium m-auto whitespace-nowrap">
              Not Recommended
            </div>
          </div>
        </>
      );
    case "Failed":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-red-300 bg-opacity-10 rounded-[5px] border border-red-300">
            <div className="text-red-500 text-xs font-medium m-auto whitespace-nowrap">
              Failed
            </div>
          </div>
        </>
      );
    case "Could Not Verified":
      return (
        <>
          <div className="flex flex-row p-[6px] w-auto h-[30px] bg-red-300 bg-opacity-10 rounded-[5px] border border-red-300">
            <div className="text-red-500 text-xs font-medium m-auto whitespace-nowrap">
              Could Not Verified
            </div>
          </div>
        </>
      );
    default:
      return null;
  }
};

export const casesCheckList = [
  { name: "Education" },
  { name: "Identity" },
  { name: "Certificate" },
  { name: "Drug" },
  { name: "Credit" },
  { name: "Court" },
  { name: "Police" },
  { name: "Experience" },
  { name: "Address" },
  { name: "Reference" },
  { name: "VideoKyc" },
  { name: "GlobalDatabase" },
  { name: "SexOffender" },
  { name: "EDD" },
  { name: "DISCRETECALLS" },
  { name: "KEIPROCESS" },
  { name: "VOLOHEALTHCARE" },
  { name: "REEDD_SURPRISE" },
  { name: "REEDD_APPOINTMENT" },
  { name: "UAN" },
  { name: "SOCIAL_MEDIA" },
  { name: "REPUTATIONAL_DATABASE" },
  { name: "CRIMINAL" },
  { name: "Bank" },
  { name: "PROFESSIONAL_LICENSE" },
];

export const salaryTypeRemark = [
  { name: "Salaried Monthly" },
  { name: "Commission-based" },
  { name: "Contract-based" },
  { name: "Daily wage" },
  { name: "Hourly" },
];

export const modeOfVerificationRemark = [
  { name: "Online" },
  { name: "Email" },
  { name: "Visit" },
  { name: "Post reply" },
  { name: "Digilocker" },
  { name: "Stamp" },
  { name: "Verbal" },
];

export const CourseRemark = [
  { name: "Regular" },
  { name: "Distance" },
  { name: "Open" },
  { name: "Correspondence" },
  { name: "Other" },
];

export const educationCategories = [
  { name: "Highest_Level_of_Education" },
  { name: "Phd" },
  { name: "Post_Graduate" },
  { name: "Under_Graduate" },
  { name: "Graduation" },
  { name: "12th_Grade" },
  { name: "10th_Grade" },
];

export const selectRemark = [{ name: "found" }, { name: "Not found" }];
export const addressType = [
  { name: "Current Address" },
  { name: "Permanent Address" },
];

export const maritalStatus = [
  { name: "Married" },
  { name: "Unmarried" },
  { name: "Widow" },
  { name: "Divorced" },
];

export const remarkStatus = [{ name: "Verified" }, { name: "Not Verified" }];
export const modeOfVerification = [
  { name: "Verbal" },
  { name: "Written" },
  { name: "Physical " },
  { name: "Online" },
];

export const optionForRecord = [
  { name: "Record Found" },
  { name: "No Record Found" },
];

export const statusRemark = [
  { name: "Discrepancy" },
  { name: "Negative" },
  { name: "Positive" },
  { name: "Unable to verify" },
];

export const genderOptions = [
  { name: "Male" },
  { name: "Female" },
  { name: "Others" },
];
export const finalRemark = [
  { name: "Verified Clear" },
  { name: "Major Discrepancy" },
  { name: "Pending" },
  { name: "Minor Discrepancy" },
  { name: "Inconclusive" },
];

export const educationAnnexure = [
  { name: "E-1" },
  { name: "E-2" },
  { name: "E-3" },
  { name: "E-4" },
  { name: "E-5" },
];

export const experienceAnnexure = [
  { name: "Ex-1" },
  { name: "Ex-2" },
  { name: "Ex-3" },
  { name: "Ex-4" },
  { name: "Ex-5" },
];

export const addressAnnexure = [
  { name: "A-1" },
  { name: "A-2" },
  { name: "A-3" },
  { name: "A-4" },
  { name: "A-5" },
];

export const courtAnnexure = [
  { name: "C-1" },
  { name: "C-2" },
  { name: "C-3" },
  { name: "C-4" },
  { name: "C-5" },
];

export const referenceAnnexure = [
  { name: "R-1" },
  { name: "R-2" },
  { name: "R-3" },
  { name: "R-4" },
  { name: "R-5" },
];

export const insufficiencyStatusFilter = [
  { name: "Select Insufficieny", value: "" },
  { name: "True", value: "true" },
  { name: "False", value: "false" },
];

export const taskStatusFilter = [
  { name: "InProgress", value: "InProgress" },
  { name: "Completed", value: "Completed" },
];

export const selectCriminalForm = [
  { name: "Law Firm" },
  { name: "Stamp" },
  { name: "Process" },
];

export const selectCourt = [
  { name: "Court Check" },
  { name: "Court Check (Law firm)" },
];
