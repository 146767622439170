import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";

const GlobalDatabaseCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
    // height: "590px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },

  titleRow: {
    fontSize: 10,
    fontWeight: "bold",
    padding: 5,
    backgroundColor: "#f5f5f5",
    borderBottom: "0.5px solid black",
  },
  descriptionText: {
    fontSize: 8,
    padding: 5,
    lineHeight: 1.5,
  },
  headerCell: {
    width: "50%",
    border: "0.5px solid black",
    // backgroundColor: "#f5f5f5",
    padding: 5,
    fontSize: 7,
    fontWeight: "bold",
  },
  cell: {
    width: "50%",
    border: "0.5px solid black",
    padding: 5,
    fontSize: 7,
    textAlign: "left",
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

const getStatusColor = (status) => {
  switch (status) {
  case "Major Discrepancy":
    return "red";
  case "Minor Discrepancy":
    return "orange";
  case "Verified Clear":
    return "green";
  case "Insufficiency":
    return "#859bed";
  case "Unable to Verify":
    return "orange";
  case "Stop Check":
    return "gray";
  case "Rejected":
    return "red";
  case "Approved":
    return "green";
  case "Completed":
    return "green";
  case "InProgress":
    return "#859bed";
  case "Pending":
    return "orange";
  case "BcaApproval":
    return "green";
  case "BcaRejection":
    return "red";
  case "Positive":
    return "green";
  case "Negative":
    return "red";
  case "Recommended":
    return "yellow";
  case "Not Recommended":
    return "#859bed";
  case "Failed":
    return "red";
  case "Could Not Verified":
    return "red";
  default:
    return "yellow";
  }
};

export const PamacGlobalDatabaseDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [globalDatabaseInfo, setGlobalDatabaseInfo] = useState([]);

  const getGlobalDatabaseInfo = (id) => {
    if (id) {
      ApiService.get(`globalDatabaseCheck/${id}`)
        .then((res) => {
          const data = res?.data?.docs;
          if (data && data.length > 0) {
            setGlobalDatabaseInfo(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getGlobalDatabaseInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (globalDatabaseInfo.length > 0) {
      onDataUpdate({
        globalDatabaseInfo,
        renderGlobalDatabaseDetails: () => renderGlobalDatabaseDetails(),
      });
    }
  }, [globalDatabaseInfo]);

  const renderGlobalDatabaseDetails = () => {
    return (
      <>
        {globalDatabaseInfo?.length > 0 && (
          <>
            {globalDatabaseInfo.map((globalDatabaseEvidence, index) => (
              <React.Fragment key={index}>
                {/* Render table on one page */}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: 5,
                    marginTop: 30,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  >
                    Database Verification
                  </Text>

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        marginRight: 5,
                      }}
                    >
                      Check Status:
                    </Text>
                    <View
                      style={{
                        backgroundColor: getStatusColor(
                          globalDatabaseEvidence?.globalDatabase?.status
                        ),
                        paddingVertical: 2,
                        paddingHorizontal: 10,
                        borderRadius: 4,
                      }}
                    >
                      <Text
                        style={{
                          color: "black",
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                      >
                        {globalDatabaseEvidence?.globalDatabase?.status}
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "bold",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Database Searches
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "left",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "bold",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Global Database Searches
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Serious and Organized Crimes Database
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      No Record
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      Annexure A
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Regulatory Database
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      No Record
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      Annexure B
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Compliance Database
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      No Record
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      Annexure C
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Indian Specific Criminal Records Database
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      No Record
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      Annexure D
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Civil Litigation Database Checks
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      No Record
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      Annexure E
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      INDIA-SPECIFIC CIVIL LITIGATION DATABASES SEARCHES
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      No Record
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      Annexure F
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Web and Media Searches
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      No Record
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      Annexure G
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Sanctions Check
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      No Record
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      Annexure H
                    </Text>
                  </View>
                </View>

                <View break />

                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        //   textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "bold",
                        //   backgroundColor: "#d3d3d3",
                      }}
                    >
                      Annexure A
                    </Text>
                  </View>
                  {/* Title */}
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        //   textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "bold",
                        //   backgroundColor: "#d3d3d3",
                      }}
                    >
                      GLOBAL SERIOUS & ORGANIZED CRIMES DATABASES
                    </Text>
                  </View>

                  {/* Description */}
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.descriptionText}>
                      Organized Crime is one of the major factors in money
                      laundering and its move into legitimate business has seen
                      a number of corporations becoming embroiled in business
                      dealings with such groups. This database is comprised of
                      individuals that have been identified or are
                      &apos;wanted&apos; by international law enforcement
                      agencies such as the FBI, UK&apos;s Serious Fraud Office,
                      India&apos;s Central Bureau of Investigations and the
                      Royal Canadian Mounted Police.
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        //   textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                        fontWeight: "bold",
                      }}
                    >
                      Most Wanted Lists: Global
                    </Text>
                  </View>

                  {/* Most Wanted Lists: Global */}
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Interpol Most Wanted
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  {/* Most Wanted Lists: US and Canada */}
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        //   textAlign: "center",
                        padding: 5,
                        fontSize: 9,
                        fontWeight: "bold",
                      }}
                    >
                      Most Wanted Lists: US and Canada
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Air Force Fugitives: Air Force of Special Investigation,
                      USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Boston Police, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Bureau of Alcohol, Tobacco and Firearms, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      City of South Portland Police, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Delaware State Police, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Department of Illinois Corrections, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      FBI&apos;s Most Wanted, Milwaukee, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Federal Bureau of Investigation, USA [includes hijack
                      suspects, most wanted & FBI seeking information]
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Fort Lauderdale Police Department, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Honolulu Police, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      International Broadcasting Bureau (IBB), USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Kansas Bureau of Investigation, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      New Jersey Division of Criminal Justice, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Massachusetts State Police, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Michigan State Police, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Mississippi Department of Public Safety, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Federal Police Department, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Monterey County Sheriff&apos;s Department, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Montgomery County Sheriff&apos;s Office, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                </View>

                <View break />

                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      New Jersey State Police, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Office of New York City Police Department, USA
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Oregon Department of Corrections, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Rhode Island State Police, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      San Diego County Sheriff&apos;s Department, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Scarborough Police Department, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Most Wanted Fugitives: State of Minnesota Department of
                      Corrections, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Tennessee Bureau of Investigation, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Most Wanted Fugitives: Texas Department of Public Safety,
                      USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        //   textAlign: "center",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      GLOBAL SERIOUS & ORGANIZED CRIMES DATABASES [CONTD.]
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The Los Angeles Police Department (LAPD), USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The Yonkers Police Department, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      U.S. Immigration and Customs Enforcement, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      U.S. Secret Service, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Ulster County Sheriffs Office, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      United States Department of Justice (DOJ), USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      United States Marshals Service, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      US Naval Criminal Investigative Service, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      US Postal Inspection Service, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Virginia State Police, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Washtenaw County Sheriffs Office, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      West Virginia State Police, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Wichita Police Department, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Winchester, Kentucky Police Department, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Royal Canadian Mounted Police, Canada
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Abbotsford Police Department, Canada
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Halifax Regional Police, Canada
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Hamilton Police Service, Canada
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Waterloo Regional Police, Canada
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                </View>

                <View break />

                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Ontario Provincial Service, Canada
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The Peel Regional Police, Canada
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        //   textAlign: "center",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                        //   backgroundColor: "#d3d3d3",
                      }}
                    >
                      Most Wanted Lists: Europe and Central Asia
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Brandenburg Police, Germany
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Federal Criminal Investigation Office, Germany
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The Bavarian Police, Germany
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The Police of Saxony-Anhalt (Sachsen-Anhalt) County,
                      Germany
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The State Criminal Police Agency of North Rhine,
                      Westphalia (Nordrhein Westfalen), Germany
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      International Police Cooperation Division, Greece
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The Netherlands Police Department, The Netherlands
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Swiss Police, Switzerland
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Proscribed Organizations, United Kingdom
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Scotland Yard’s Most Wanted, United Kingdom
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The Metropolitan Police Service, United Kingdom
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Serious Frauds Office, United Kingdom
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      National Crime Squad, United Kingdom
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Ministry of the Interior, Israel
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        //   textAlign: "center",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                        //   backgroundColor: "#d3d3d3",
                      }}
                    >
                      Most Wanted Lists: Africa
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      South African Police Service, South Africa
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                </View>
                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        //   textAlign: "center",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      GLOBAL SERIOUS & ORGANIZED CRIMES DATABASES [CONTD.]
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        //   textAlign: "center",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Most Wanted Lists: Asia Pacific
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Criminal Investigation Bureau, Taiwan
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Bureau of Investigation, Ministry of Justice, Taiwan
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Dubai Police, UAE
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Hong Kong Police Force, Hong Kong
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      New Zealand Police, New Zealand
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      National Bureau Of Investigation, Philippines
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Philippines National Police, Philippines
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Central Narcotics Bureau, Singapore
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Crime Net, Australia
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Queensland Police Service, Australia
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                </View>
                <View> </View>
                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        //   textAlign: "center",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Annexure B
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        //   textAlign: "center",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      GLOBAL REGULATORY AUTHORITIES DATABASES
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.descriptionText}>
                      This database details companies and individuals who have
                      been censored by financial regulatory authorities for
                      regulatory breaches. The database is sourced from
                      information provided by the numerous international
                      regulatory authorities. A scan through this database
                      addresses the reputation, financial and legal risks
                      associated with a new business relationship when it is
                      instituted by a financial institution or corporation.
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        //   textAlign: "center",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Global Regulatory Bodies
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Bureau of Industry and Security
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      World Bank Debarred Parties
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Defense Trade Controls (DTC) Debarred Parties
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      US and Canadian Regulatory Bodies
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      American Stock Exchange, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Commodities and Futures Trading Commission (CFTC), USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Excluded Parties List System [includes General Services
                      Administration (GSA)], USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Federal Deposit and Insurance Corporation (FDIC), USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Federal Reserve Board (FRB), USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Financial Crimes Enforcement Network, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                </View>
                <View break />
                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      HHS-Office of Inspector General (OIG), USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      National Association of Securities Dealers (NASD), USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      National Credit Union Association (NCUA), USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      New York Stock Exchange (NYSE), USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Office Comptroller of Currency (OCC), USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Office of Thrift Supervision (OTS), USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Oregon Department of Consumer & Business Services, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The Illinois Office of Banks and Real Estate, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      US Securities and Exchange Commission, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      New York State Insurance Department, USA
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      US Food & Drug Administration
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Alberta Securities Commission, Canada
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      British Columbia Securities Commission (BCSC), Canada
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Investment Dealers Association of Canada (IDA), Canada
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Office of Superintendents of Financial Institutions
                      (OSFI), Canada
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Ontario Securities Commission (OSC), Canada
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Toronto Stock Exchange, Canada
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Canada Revenue Agency, Canada
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Cayman Islands Monetary Authority, Cayman Islands
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Central Bank of Bahamas, Bahamas
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      European Regulatory Bodies
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Companies House, United Kingdom
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Financial Services Authority (FSA), United Kingdom
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      GLOBAL REGULATORY AUTHORITIES DATABASES (CONTD.)
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Lloyds of London (Lloyds), United Kingdom
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Financial Services Compensation Scheme, United Kingdom
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Assets Recovery Agency, United Kingdom
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Personal Investment Authority, United Kingdom
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Securities and Futures Authority, United Kingdom
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                </View>
                <View break />
                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Charity Commission for England and Wales, United Kingdom
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      HM Customs and Excise, United Kingdom
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Malta Financial Services Authority, Malta
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The Netherlands Authority For the Financial Markets,
                      Netherlands
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Office of the Director of Corporate Enforcement (ODCE),
                      Ireland
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The Irish Financial Services Regulatory Authority, Ireland
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The Financial Supervision Commission, Isle of Man
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Italian Securities Commission (Consob), Italy
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Jersey Financial Securities Commission, Jersey
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Gibraltar Financial Services Commission, Gibraltar
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Asia Pacific Regulatory Bodies
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Australian Stock Exchange, Australia
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Australian Securities and Investment Commission (ASIC),
                      Australia
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Bangladesh Securities and Commission, Bangladesh
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      China Customs, China
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      CSRC (China Securities Regulatory Commission), China
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Hong Kong Securities & Futures Commission (HKSFC), Hong
                      Kong
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Hong Kong Monetary Authority – Warnings, Hong Kong
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Independent Commission against Corruption, Hong Kong
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Securities Exchange Board of India (SEBI), India
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Indonesian Capital Market Supervisory Agency (BAPEPAM),
                      Indonesia
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Tokyo Stock Exchange (TSE), Japan
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Japanese Securities and Exchange Surveillance Commission,
                      Japan
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Financial Supervisory Service, Korea Republic
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Malaysia Securities Commission (MSC), Malaysia
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      New Zealand Companies House, New Zealand
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      New Zealand Securities Commission (NZSC), New Zealand
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      New Zealand Serious Fraud Office, New Zealand
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Securities Exchange Commission of Pakistan (SECP),
                      Pakistan
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                </View>
                <View break />
                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Singapore Stock Exchange, Singapore
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Monetary Authority of Singapore - Investors Alert,
                      Singapore
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Thailand Securities and Exchange Commission, Thailand
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                </View>
                <View> </View>

                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Annexure C
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      GLOBAL COMPLIANCE AUTHORITIES DATABASES
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.descriptionText}>
                      This database is comprised of Government Prohibited
                      Persons Lists, Terrorists, International Narcotics
                      Traffickers and those engaged in activities related to the
                      proliferation of weapons of mass destruction. It also
                      includes Companies and individual who have been named in
                      legal documents and press reports as having aided the 9/11
                      attacks and other terrorist outrages. The war crime
                      database herein contains information on individuals and
                      entities that have been responsible for serious violations
                      of international humanitarian law and genocide globally.
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Office of Foreign Assets Control (OFAC): Specially
                      Designated Nationals & Blocked Persons and names that have
                      been deleted from the OFAC list
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Officials of OFAC Blocked Countries
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Bank of England Sanctions List
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      United Nations Consolidated List
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      European Union Terrorist List
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Monetary Authority of Singapore
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Hong Kong Monetary Authority
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Australian Department of Foreign Affairs and Trade (DFAT)
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Central Bank of UAE
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The Australian Transaction Reports and Analysis Centre
                      (Austrac)
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Reserve Bank of Australia
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      OSFI Consolidated List, Canada
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Rwanda International War Crimes Database
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Yugoslavia International War Crimes Database
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Arms Trafficking and Weapons of Mass Destruction
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      9/11 Subpoena Database
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Global Money Laundering Database
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                </View>

                <View break />

                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Annexure D
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      INDIA-SPECIFIC CRIMINAL RECORDS DATABASES
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Central Bureau of Investigation Most Wanted List
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      A comprehensive database maintained by Central Bureau of
                      Investigation (CBI), India’s premier investigating agency,
                      responsible for a wide variety of criminal and national
                      security matters consisting records of most wanted
                      criminals and terrorists.
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Supreme Court Records Check
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      A comprehensive collection of the full text of case laws
                      on all subjects, compiled from 29 different acts related
                      to Supreme Court case laws dating back to 1950
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      High Court Records Check
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      This database consists of comprehensive records of the
                      full text of High Court cases dating back to 1971,
                      compiled from 4 different acts
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Central Vigilance Commission Corrupt Officers Database
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Records of Government or Public Sector Officials that the
                      Central Vigilance Commission of the Indian Government has
                      taken action against for corruption since the year 2000
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Most Wanted Criminals
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Database of most wanted persons compiled from various
                      sources of the Indian Police Department, including Delhi
                      Police, Tripura Police, Himachal Pradesh Police, Punjab
                      Police, Bangalore City Police and CID - Andhra Pradesh
                      State.
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                </View>
                <View> </View>

                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Annexure E
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      INDIA-SPECIFIC CIVIL LITIGATION DATABASES SEARCHES
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Labour & Service Law Violations
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The full text of Supreme Court, High Court and Tribunal
                      cases from 1956, also including Central Administrative
                      Tribunal (CAT) cases .These are compiled from 41 different
                      Acts related to Labour Laws
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Consumer Protection Violation
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The full text of Supreme Court , High Court , National
                      Consumer Disputes Redressal Commission (NCDRC) and State
                      Consumer Disputes Redressal Commission (SCDRC) case laws
                      from 1933 onwards .These cover the Consumer Protection Act
                      & Rules1986 and Monopolies & Restrictive Trade Practices
                      Act 1969
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Environmental Protection Violation
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Detailed text of Supreme Court cases since 1956, complied
                      from 06 different Acts related to Environmental
                      Protection.
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                </View>
                <View break />

                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Arbitration and Conciliation
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Full text of Supreme Court and High court judgements ,
                      compiled from 03 different Acts related to Arbitration and
                      Conciliation and UNITRAL Model Law.
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Direct Taxes Violation
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The full text of Supreme Court, High Court and Tribunal
                      cases from 1933 and the Tribunal Judgments from 1976
                      compiled from 18 Acts related to Direct Taxes. It also
                      includes Central Board of Direct Taxes (CBDT)
                      Notifications & Circulars dating back to 1961.
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Excise And Customs Violations
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The full text of Supreme Court, High Court and Tribunal
                      cases from 1953 onwards and Notifications of the Central
                      Board of Excise and Customs (CBEC) from1984 onwards ,
                      compiled from 25 different Acts pertaining to Excise and
                      Customs .
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Company Law Violations
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The full text of Supreme Court, High Court and Company Law
                      Board (CLB) judgments from 1931onwards , compiled from 44
                      different Acts related to Company Law
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Sales Tax Violations
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The full text of Supreme Court, High Court and Tribunal
                      cases since 1951, compiled from 36 different Acts related
                      to Sales tax
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                </View>
                <View> </View>

                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Annexure F
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      INDIA-SPECIFIC CREDIT & REPUTATIONAL RISK DATABASES
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Loan Defaulters List - Reserve Bank of India
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      This database contains records of borrowers over the last
                      four quarters’ listing published by India’s Federal Bank
                      i.e. the Reserve Bank of India, including companies and
                      their directors who have defaulted in their dues to
                      scheduled commercial banks and financial institutions. It
                      includes details of suits filed against those who have
                      defaulted on loan of INR 1 crore & above (US $ 2,29,000+)
                      and those who have willfully defaulted on loans of INR 25
                      lacs and above (US $ 50,000 +).
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Securities and Exchange Board of India (SEBI) Watch List
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      The Securities and Exchange Board of India is the
                      regulatory body for governing the capital markets in
                      India. SEBI takes action against non- complaint market
                      intermediaries and corporate whose practices are not in
                      compliances with the Code of Conduct prescribed by SEBI.
                      The search includes hearings and documentation of the
                      Stock Exchange Enforcement division (Chairman&apos;s and
                      Securities Appellate Tribunal&apos;s orders).
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                </View>
                <View break />

                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Bombay Stock Exchange (BSE) & National Stock Exchange
                      (NSE) Companies Watch list
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      This database includes names of companies, which have been
                      suspended from trading or put in &apos;Z&apos; category
                      due to violation of clauses of listing agreement with the
                      BSE or NSE. It also includes companies that have vanished
                      without any known whereabouts, categorized as
                      &apos;Unknown&apos; NSE
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Department of Company Affairs Vanishing Companies Database
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      This database includes names of companies that have
                      disappeared, those that have misused funds mobilized from
                      investors and unscrupulous promoters who have misused
                      funds raised from the public. This database is compiled
                      jointly by the Department of Company Affairs, Securities
                      and Exchange Board of India (SEBI) and various stock
                      exchanges.
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Indian Prosecuted Companies Database
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      This database includes names of companies that have
                      defaulted in filing the annual returns and/or have been
                      prosecuted under various sections of Indian Companies Act
                      1956 by the regional offices of Registrar of Companies.
                      This database is compiled from the records of the offices
                      of Registrar of Companies of Karnataka,Jammu & Kashmir,
                      Kerala and Pondicherry.
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Bankruptcy Checks - Board of Industries and Financial
                      Reconstruction (BIFR) Companies
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      This database includes the list of all banks, companies
                      and other financial institutions, which have gone bankrupt
                      or have been declared as sick units.
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Directorship Watch List
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      This database covers names of directors and senior
                      employees who have been involved in failed and
                      subsequently de-listed companies, those known to have been
                      involved in the Stock Market Scam of 1992 and other more
                      recent scams and also a detailed list of those who have
                      held senior positions in companies known to have been
                      actively involved in some of the most sensational ‘scams’
                      (both political and financial) to hit India
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Cross Directorships Database
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Through a variety of databases, cross-checks for
                      directorships in Public Limited companies incorporated in
                      India
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                </View>

                <View break />

                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Annexure G
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      GLOBAL WEB & MEDIA SEARCHES
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Media Searches
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Company has access to the most comprehensive global media
                      database. Web and media searches includes global news and
                      articles from over 9,000 authoritative sources and
                      websites to identify individuals and entities associated
                      with crimes of any nature such as civil & criminal
                      matters, corruption, money laundering, serious & Organized
                      crime, terrorism etc. The sources provide news going back
                      up to 25 years and also cover local language articles.
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Internet Searches
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Company uses a number of advanced and programmable
                      Internet search tools, which allows it to search through
                      numerous international as well as India- specific search
                      engines on the Internet. This comprehensive search
                      methodology covers both the web as well as newsgroups and
                      ensures as complete coverage of the net as is possible.
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                </View>
                <View> </View>
                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      Annexure H
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      SANCTIONS DATABASE CHECKS
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        padding: 5,
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      This Sanctions List Search application (&quot;Sanctions
                      List Search&quot;) is designed to facilitate the use of
                      the Specially Designated Nationals and Blocked Persons
                      list (&quot;SDN List&quot;) and all other sanctions lists
                      administered by OFAC, including the Foreign Sanctions
                      Evaders List, the List of Persons Identified as Blocked
                      Solely Pursuant to E.O. 13599. Sanctions List Search is
                      one tool offered to assist users in utilizing the SDN List
                      and/or the various other sanctions lists; use of Sanctions
                      List Search is not a substitute for undertaking
                      appropriate due diligence. The use of Sanctions List
                      Search does not limit any criminal or civil liability for
                      any act undertaken as a result of, or in reliance on, such
                      use.
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Office of Foreign Assets Control (OFAC): specially
                      Designated Nationals & Blocked Persons and names that have
                      been deleted from the OFAC list. Sanctions checks are
                      specialized searches that include a number of government
                      sanction databases that identify individuals who are
                      prohibited from certain activities or industries
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Officials of OFAC Blocked Countries(Office of Foreign
                      Assets Control (OFAC)
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text style={GlobalDatabaseCheckStyles.headerCell}>
                      Healthcare Sanctions Checks
                    </Text>
                    <Text style={GlobalDatabaseCheckStyles.cell}>
                      No Record
                    </Text>
                  </View>
                </View>

                <View wrap={false} break />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };

  return null;
};
