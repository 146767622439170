import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Link } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const GlobalDatabaseCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
    // height: "590px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

const getStatusColor = (status) => {
  switch (status) {
    case "Major Discrepancy":
      return "red";
    case "Minor Discrepancy":
      return "orange";
    case "Verified Clear":
      return "green";
    case "Insufficiency":
      return "#859bed";
    case "Unable to Verify":
      return "orange";
    case "Stop Check":
      return "gray";
    case "Rejected":
      return "red";
    case "Approved":
      return "green";
    case "Completed":
      return "green";
    case "InProgress":
      return "#859bed";
    case "Pending":
      return "orange";
    case "BcaApproval":
      return "green";
    case "BcaRejection":
      return "red";
    case "Positive":
      return "green";
    case "Negative":
      return "red";
    case "Recommended":
      return "yellow";
    case "Not Recommended":
      return "#859bed";
    case "Failed":
      return "red";
    case "Could Not Verified":
      return "red";
    default:
      return "yellow";
  }
};

const formatDate = (dateString) => {
  let date;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const VleaderGlobalDatabaseDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [globalDatabaseInfo, setGlobalDatabaseInfo] = useState([]);

  const getGlobalDatabaseInfo = (id) => {
    if (id) {
      ApiService.get(`globalDatabaseCheck/${id}`)
        .then((res) => {
          setGlobalDatabaseInfo(res?.data?.docs);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getGlobalDatabaseInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (globalDatabaseInfo.length > 0) {
      onDataUpdate({
        globalDatabaseInfo,
        renderGlobalDatabaseDetails: () => renderGlobalDatabaseDetails(),
      });
    }
  }, [globalDatabaseInfo]);

  const AsyncImage = ({ fileUrl, className, alt }) => {
    const imgSrc = useAsyncImageSrc(fileUrl);

    return (
      <Link href={imgSrc} className={className || ""} target="_blank">
        {alt || "Click here to view the image"}
      </Link>
    );
  };

  const renderGlobalDatabaseDetails = () => {
    return (
      <>
        {globalDatabaseInfo?.length > 0 && (
          <>
            {globalDatabaseInfo.map((globalDatabaseEvidence, index) => (
              <React.Fragment key={index}>
                {/* Render table on one page */}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: 5,
                    marginTop: 30,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  >
                    Global Database Verification Report
                  </Text>

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        marginRight: 5,
                      }}
                    >
                      Check Status:
                    </Text>
                    <View
                      style={{
                        backgroundColor: getStatusColor(
                          globalDatabaseEvidence?.globalDatabase?.status
                        ),
                        paddingVertical: 2,
                        paddingHorizontal: 10,
                        borderRadius: 4,
                      }}
                    >
                      <Text
                        style={{
                          color: "black",
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                      >
                        {globalDatabaseEvidence?.globalDatabase?.status}
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={GlobalDatabaseCheckStyles.table}>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "100%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 10,
                        fontWeight: "bold",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Antecedents Verified
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Date of Verification
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {globalDatabaseEvidence?.globalDatabase
                        ?.dateOfVerification
                        ? formatDate(
                            globalDatabaseEvidence?.globalDatabase
                              ?.dateOfVerification
                          )
                        : "NA"}
                    </Text>
                  </View>
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Time of Verification
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {
                        globalDatabaseEvidence?.globalDatabase
                          ?.timeOfVerification
                      }
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Verified by
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {globalDatabaseEvidence?.globalDatabase?.verifiedBy}
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Mode of Verification
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {
                        globalDatabaseEvidence?.globalDatabase
                          ?.modeOfVerification
                      }
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Regulatory Authorities Check
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {globalDatabaseEvidence?.globalDatabase?.regulatory}
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Compliance Checks
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {globalDatabaseEvidence?.globalDatabase?.compliance}
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Serious and Organized Crimes
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {globalDatabaseEvidence?.globalDatabase?.seriousCrimes}
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Web and Media Searches
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {globalDatabaseEvidence?.globalDatabase?.mediaSearch}
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Verification Remarks
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {
                        globalDatabaseEvidence?.globalDatabase
                          ?.verificationRemark
                      }
                    </Text>
                  </View>

                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Additional Comments
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {
                        globalDatabaseEvidence?.globalDatabase
                          ?.additionalComment
                      }
                    </Text>
                  </View>
                  {/* ------------------------------------------- */}
                  <View style={GlobalDatabaseCheckStyles.tableRow}>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                        fontWeight: "900",
                        backgroundColor: "#d3d3d3",
                      }}
                    >
                      Proof
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                        border: "0.5px solid black",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 8,
                      }}
                    >
                      {globalDatabaseEvidence?.globalDatabase?.uploadProofUrl?.map(
                        (url, proofIndex) => {
                          return (
                            <Text key={proofIndex} style={{ marginRight: 5 }}>
                              <AsyncImage
                                fileUrl={url}
                                alt={`Global Database evidence ${proofIndex + 1}`}
                              />
                              {proofIndex <
                                globalDatabaseEvidence.globalDatabase
                                  .uploadProofUrl.length -
                                  1 && (
                                <Text style={{ marginRight: 5 }}> , </Text>
                              )}
                            </Text>
                          );
                        }
                      )}
                    </Text>
                  </View>
                </View>

                {/* <View break /> */}

                <View wrap={false} break />
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };

  return null;
};
