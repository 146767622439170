import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { useLocation } from "react-router";
import ApiService from "../../../services/APIServices.js";
// import useAsyncImageSrc from "../../../Helper/useAsyncImageSrc.jsx";

const ReferenceCheckStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
    marginVertical: 5,
    borderStyle: "solid",
    borderWidth: 0.5,
    marginTop: "20px",
    height: "477px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "25%",
    border: "0.5px solid black",
    textAlign: "center",
    padding: 5,
    fontSize: 8,
  },
  centeredImageContainer: {
    marginTop: 20,
    paddingTop: 10,
    minHeight: 400, // Ensure minimum height to enforce page break
    justifyContent: "center", // Center image vertically
    alignItems: "center", // Center image horizontally
  },
  centeredImage: {
    height: 400,
    width: "100%",
  },
});

const getStatusColor = (status) => {
  switch (status) {
  case "Major Discrepancy":
    return "red";
  case "Minor Discrepancy":
    return "orange";
  case "Verified Clear":
    return "green";
  case "Insufficiency":
    return "#859bed";
  case "Unable to Verify":
    return "orange";
  case "Stop Check":
    return "gray";
  case "Rejected":
    return "red";
  case "Approved":
    return "green";
  case "Completed":
    return "green";
  case "InProgress":
    return "#859bed";
  case "Pending":
    return "orange";
  case "BcaApproval":
    return "green";
  case "BcaRejection":
    return "red";
  case "Positive":
    return "green";
  case "Negative":
    return "red";
  case "Recommended":
    return "yellow";
  case "Not Recommended":
    return "#859bed";
  case "Failed":
    return "red";
  case "Could Not Verified":
    return "red";
  default:
    return "yellow";
  }
};

export const VleaderReferenceDetailCheck = ({ onDataUpdate }) => {
  const location = useLocation();
  const [referenceInfo, setReferenceInfo] = useState([]);

  const getReferenceInfo = (id) => {
    ApiService.get(`referenceCheck/${id}`)
      .then((res) => {
        setReferenceInfo(res?.data?.docs);
        // SetFileUplaod(data?.education?.reportUrl || []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const id = location?.state?.id;
    if (id) {
      getReferenceInfo(id);
    } else {
      console.log("No id found in location state");
    }
  }, [location?.state?.id]);

  useEffect(() => {
    if (referenceInfo.length > 0) {
      onDataUpdate({
        referenceInfo,
        renderReferenceDetails: () => renderReferenceDetails(),
      });
    }
  }, [referenceInfo]);

  // const AsyncImage = ({ fileUrl, className, alt }) => {
  //   const imgSrc = useAsyncImageSrc(fileUrl);

  //   return (
  //     // <img src={imgSrc} alt={alt || "image"} className={className || ""} />
  //     <Image src={imgSrc} alt={alt || "image"} className={className || ""} />
  //   );
  // };

  const renderReferenceDetails = () => {
    return (
      <>
        <View wrap>
          {referenceInfo?.length > 0 && (
            <>
              {referenceInfo.map((referenceEvidence, index) => (
                <React.Fragment key={index}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: 5,
                      marginTop: 30,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      Reference Verification:
                    </Text>

                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          marginRight: 5,
                        }}
                      >
                        Status:
                      </Text>
                      <View
                        style={{
                          backgroundColor: getStatusColor(
                            referenceEvidence?.reference?.status
                          ),
                          paddingVertical: 2,
                          paddingHorizontal: 10,
                          borderRadius: 4,
                        }}
                      >
                        <Text
                          style={{
                            color: "black",
                            fontSize: 12,
                            fontWeight: "bold",
                          }}
                        >
                          {referenceEvidence?.reference?.status}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    key={index}
                    style={ReferenceCheckStyles.table}
                    wrap={false}
                  >
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "200%",
                          border: "0.5px solid black",
                          textAlign: "left",
                          padding: 5,
                          fontSize: 10,
                          fontWeight: "bold",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Professional Reference Verification
                      </Text>
                    </View>

                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Do you Know the subject?
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.subjectKnowledge}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        What was your professional relationship with the subject
                        and from how long to do know him?
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.professionalRelation}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        What was the subject’s reason for leaving the job?
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.leavingJob}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Is the subject eligible for rehire?
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.eligibilityRehire}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Any complaints against the subject?
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.complaint}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Would you like to recommend the subject for working in
                        top MNC Company?
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.recommendMNC}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Any record of the subject misrepresenting facts or
                        accounts? If yes, please specify
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.misrepresenting}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Professional Skills? (Rate -1 to 5)
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.professionalSkills}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Time management and organizational skill? (Rate -1 to 5)
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.timeManagementSkill}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Written Communication Skill? (Rate 1 to 5)
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.wriitenSkill}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Oral Communication Skill? (Rate 1 to5)
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.oralSkill}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Team Player? (Rate 1 to 5)
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.teamPlayer}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Is there any Integrity/Reliability/Disciplinary issue
                        against the subject?
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.irdIssue}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Name of respondent
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.refereeName}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Current organization of the respondent
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.organization}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Current designation of respondent
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.designation}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Referee’s contact details
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.contactNumber}
                      </Text>
                    </View>
                    <View style={ReferenceCheckStyles.tableRow}>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                          fontWeight: "900",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        Remarks
                      </Text>
                      <Text
                        style={{
                          width: "100%",
                          border: "0.5px solid black",
                          textAlign: "center",
                          padding: 5,
                          fontSize: 8,
                        }}
                      >
                        {referenceEvidence?.reference?.remark}
                      </Text>
                    </View>
                  </View>
                  <View break />
                </React.Fragment>
              ))}
            </>
          )}
        </View>
      </>
    );
  };
  return null;
};
